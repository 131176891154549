import { GET_USERS, UPDATE_USER } from '../actions/types';
export default function (state=false, action) {
    console.log("state",state)
    console.log("action",action)
    switch (action.type) {
        case GET_USERS:
            return action.payload;
        case UPDATE_USER:
                    return action.payload;
           
        default:
            return state;
    }
}

// const newItem = 0;
// return [    // a new array
//     newItem,  // add the new item first
//     ...state  // then explode the old state at the end
// ];
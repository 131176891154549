//import "ant-design-pro/dist/ant-design-pro.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-table/react-table.css";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { App } from "./components/App";
import reducers from "./reducers";

const saveToLocalStorage = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};
const persistedState = loadFromLocalStorage();
const store = createStore(reducers, persistedState, applyMiddleware(thunk));
store.subscribe(() => saveToLocalStorage(store.getState()));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);

import { message, notification } from 'antd';
export const openNotificationWithIcon = (type, title) => {
  if (type === 'success') {
    notification[type]({
      message: `Successfuly`,
      description:
        `${title}`,
      style: {
        width: 600,
        marginLeft: 335 - 600,
      },
    });

  } else if (type === 'error') {
    notification[type]({
      message: `Error`,
      description:
        `${title}`,
      style: {
        width: 600,
        marginLeft: 335 - 600,
      },
    });
  } else {
    notification[type]({
      message: `Warning`,
      description:
        `${title}`,
      style: {
        width: 600,
        marginLeft: 335 - 600,
      },
    });
  }

};

export const holdingAction = (action) => {
  return message.loading(`${action} in progress..`, 0.75)
}



import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { CreateClient } from "./admin/CreateClient";
import { CreateUser } from "./admin/CreateUser";
import { DailyTarget } from "./admin/DailyTarget";
import { ManageClientUI } from "./admin/ManageClient";
import { ManageKPI2 } from "./admin/ManageKPI2";
import { SystemManagerUI } from "./admin/ManagerContainer/SystemManager";
import { ManageBusinessRepUI } from "./admin/ManageBusinessRep";
//Admin Panel
import { ManageUser } from "./admin/ManageUser";
import { NavBar } from "./cells/navBar";
//Regular
import { ManageShipmentUI } from "./client/ManageShipment";
import SkidLabelDocument from "./client/SkidLabelDocument";
import SkidLabelDocumentPO from "./client/SkidLabelDocumentPO";
import { TaskItem } from "./client/TaskItem";
import TaskShipment from "./client/TaskShipment";
import { TaskSkid } from "./client/TaskSkid";
import UpdateSelf from "./client/UpdateSelf";
import ForgetPassword from "./ForgetPassword";
import { HomeUI } from "./Home";
import { LoginUI } from "./Login";
import ResetPassword from "./ResetPassword";


export const App = () => {



  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);

  return (
    <div>
      <NavBar />
      <Router>
        <Switch>
          <Route exact path="/">
            <LoginUI />
          </Route>

          <Route exact path="/home">
            <HomeUI />
          </Route>

          <Route exact path="/task-shipment">
            <TaskShipment />
          </Route>

          <Route exact path="/task-manage-shipment">
            <ManageShipmentUI />
          </Route>

          <Route exact path="/task-skid">
            <TaskSkid />
          </Route>

          <Route exact path="/task-item">
            <TaskItem />
          </Route>

          <Route exact path="/me">
            <UpdateSelf />
          </Route>

          <Route exact path="/admin-user">
            <ManageUser />
          </Route>

          <Route exact path="/create-user">
            <CreateUser />
          </Route>

          <Route exact path="/admin-client">
            <ManageClientUI />
          </Route>

          <Route exact path="/admin-business-rep">
            <ManageBusinessRepUI />
          </Route>

          <Route exact path="/create-client">
            <CreateClient />
          </Route>

          {/*<Route path="/manage-kpi2" exact component={requireAuth(ManageKPI)} />*/}
          <Route exact path="/manage-kpi">
            <ManageKPI2 />
          </Route>

          <Route exact path="/manager">
            <SystemManagerUI />
          </Route>

          <Route exact path="/daily-target">
            <DailyTarget />
          </Route>

          <Route exact path="/reset-password">
            <ForgetPassword />
          </Route>

          <Route path="/reset-password/:token">
            <ResetPassword />
          </Route>

          <Route exact path="/SkidLabelDocument">
            <SkidLabelDocument />
          </Route>
          <Route exact path="/SkidLabelDocumentPO">
            <SkidLabelDocumentPO user={user} dispatch={dispatch} history={history} />
          </Route>
        </Switch>
      </Router>
    </div>

  );

}
import { Button, Card, Col, Row } from 'antd';
import localStorage from "local-storage";
import moment from "moment";
import React, { Component } from "react";
import Barcode from 'react-barcode';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import * as actions from "../../actions";
import { getWarehouse } from '../../actions/index';
import labelLogo from "./img/labelLogo.png";

class AllLabelsPO extends React.Component {

    componentWillMount() {

    }

    render() {

        let newRows = localStorage.get("newRowsPO")
        let warehouses = this.props.warehouses;
        console.log(newRows);
        return (
            <div >
                {newRows === undefined ? <div /> :
                    newRows.map((row) => {
                        return (
                            <div style={{ height: "100%" }} key={row.barCode}>
                                <Row type="flex" justify="space-around">
                                    <Col>
                                        <Card
                                            style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.8in", height: "6.1in" }}
                                            cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                        >
                                            <Row>
                                                <Col>
                                                    BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{moment(row["scheduledShipDate"]).format("YYYY-MM-DD")}</span>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    GROSS WEIGHT:________ KG's
                                    </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Barcode value={row.barCode} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    SHIP TO LOCATION:
                                        </Col>
                                            </Row>


                                            {warehouses.
                                                filter(warehouse => warehouse.id === row.assignedWarehouseID)
                                                .flatMap((warehouse) => {
                                                    return (<div key={warehouse.id}>
                                                        <Row>
                                                            <Col>
                                                                <div>{warehouse.address1}</div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {warehouse.address2}, {warehouse.city}, {warehouse.state}
                                                            </Col>
                                                        </Row>
                                                    </div>)
                                                })}
                                            <Row>
                                                <Col>
                                                    <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}</span>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card
                                            style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.8in", height: "6.1in" }}
                                            cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                        >
                                            <Row>
                                                <Col>
                                                    BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{moment(row.scheduledShipDate).format("YYYY-MM-DD")}</span>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    GROSS WEIGHT:________ KG's
                                    </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Barcode value={row["barCode"]} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    SHIP TO LOCATION:
                                        </Col>
                                            </Row>


                                            {warehouses.
                                                filter(warehouse => warehouse.id === row["assignedWarehouseID"])
                                                .flatMap((warehouse) => {
                                                    return (<div key={warehouse.id}>
                                                        <Row>
                                                            <Col>
                                                                <div>{warehouse.address1}</div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {warehouse.address2}, {warehouse.city}, {warehouse.state}
                                                            </Col>
                                                        </Row>
                                                    </div>)
                                                })}
                                            <Row>
                                                <Col>
                                                    <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}</span>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row type="flex" justify="space-around">
                                    <Col>
                                        <Card
                                            style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.8in", height: "6.1in" }}
                                            cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                        >
                                            <Row>
                                                <Col>
                                                    BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{moment(row["scheduledShipDate"]).format("YYYY-MM-DD")}</span>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    GROSS WEIGHT:________ KG's
                                    </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Barcode value={row["barCode"]} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    SHIP TO LOCATION:
                                        </Col>
                                            </Row>


                                            {warehouses.
                                                filter(warehouse => warehouse.id === row["assignedWarehouseID"])
                                                .flatMap((warehouse) => {
                                                    return (<div key={warehouse.id}>
                                                        <Row>
                                                            <Col>
                                                                <div>{warehouse.address1}</div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {warehouse.address2}, {warehouse.city}, {warehouse.state}
                                                            </Col>
                                                        </Row>
                                                    </div>)
                                                })}
                                            <Row>
                                                <Col>
                                                    <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}</span>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card
                                            style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.8in", height: "6.1in" }}
                                            cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                        >
                                            <Row>
                                                <Col>
                                                    BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{moment(row["scheduledShipDate"]).format("YYYY-MM-DD")}</span>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    GROSS WEIGHT:________ KG's
                                    </Col>

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Barcode value={row["barCode"]} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    SHIP TO LOCATION:
                                        </Col>
                                            </Row>


                                            {warehouses.
                                                filter(warehouse => warehouse.id === row["assignedWarehouseID"])
                                                .flatMap((warehouse) => {
                                                    return (<div key={warehouse.id}>
                                                        <Row>
                                                            <Col>
                                                                <div>{warehouse.address1}</div>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {warehouse.address2}, {warehouse.city}, {warehouse.state}
                                                            </Col>
                                                        </Row>
                                                    </div>)
                                                })}
                                            <Row>
                                                <Col>
                                                    <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}</span>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })}

            </div>
        );
    }
}



class SkidLabelDocumentPO extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const user = this.props.user;
        const dispatch = this.props.dispatch;
        document.getElementById("navbar").style.display = "none"
        getWarehouse(user, dispatch);
    }

    render() {

        let newRows = localStorage.get("newRowsPO")
        let warehouses = this.props.warehouses;
        return (
            <div>

                <Row type="flex" justify="space-around" align="bottom">
                    {<Col >
                        <h1>Batch {newRows[0].barCode} to {newRows[(newRows.length) - 1].barCode}</h1>
                    </Col>}
                    <Col >
                        <Button type="primary" icon="download" >
                            <ReactToPrint
                                trigger={() => <a style={{ color: "black" }} href="#">Print BarCodes or save as PDF</a>}
                                content={() => this.componentRef}
                            />
                        </Button>
                    </Col>
                </Row>

                <AllLabelsPO warehouses={warehouses} ref={el => (this.componentRef = el)} />
            </div>
        )
    }

}



const mapStateToProps = (state) => {
    return {
        warehouses: state.warehouses
    }
};

export default connect(mapStateToProps, actions)(SkidLabelDocumentPO);
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import bufferReducer from './bufferReducer';
import carriersReducer from './carriersReducer';
import clientReducer from './clientReducer';
import companyReducer from './companyReducer';
import dailyKPIReducer from './dailyKPIReducer';
import dailySkidKPIReducer from './dailySkidKPIReducer';
import errorMessageReducer from './errorMessageReducer';
import inflatorsInventoryReducer from './inflatorsInventoryReducer';
import manageUsersReducer from './manageUsersReducer';
import monthlyKPIReducer from './monthlyKPIReducer';
import monthlyRecordsReducer from './monthlyRecordsReducer';
import priceReducer from './priceReducer';
import processingInvoiceSkidsReducer from './processingInvoiceSkidsReducer';
import processItemReducer from './processItemReducer';
import productReducer from './productReducer';
import productStatusDataReducer from './productStatusDataReducer';
import productTranferReducer from './ProductTransferReducer';
import productTypesReducer from './productTypesReducer';
import purchaseOrderReducer from './purchaseOrderReducer';
import quickBookTokenReducer from './quickBookTokenReducer';
import receivingInvoiceSkidsReducer from './receivingInvoiceSkids';
import reportReducer from './reportReducer';
import shipmentReducer from './shipmentReducer';
import skidDimensionsReducer from './skidDimensionsReducer';
import skidReducer from './skidReducer';
import skidStatusDataReducer from './skidStatusDataReducer';
import usersReducer from './usersReducer';
import warehouseReducer from './warehouseReducer';
import weeklyKPIReducer from './weeklyKPIReducer';
import weeklyRecordsReducer from './weeklyRecordsReducer';
import weeklySkidKPIReducer from './weeklySkidKPIReducer';
import weeklySkidRecordsReducer from './weeklySkidRecordsReducer';
import businssRepListWithPOReducer from './bussRepListReducer';
import productsTableDataReducer from './productsTableReducer';
import producctTypeListReducer from './productTypeListReducer';

export default combineReducers({
    authenticated: authReducer,
    errorMessage: errorMessageReducer,
    users: usersReducer,
    skids: skidReducer,
    warehouses: warehouseReducer,
    products: productReducer,
    processItems: processItemReducer,
    shipments: shipmentReducer,
    weeklyKPI: weeklyKPIReducer,
    monthlyKPI: monthlyKPIReducer,
    companies: companyReducer,
    weeklyRecords: weeklyRecordsReducer,
    dailyKPI: dailyKPIReducer,
    monthlyRecords: monthlyRecordsReducer,
    inflatorsInventory: inflatorsInventoryReducer,
    dailySkidKPI: dailySkidKPIReducer,
    weeklySkidKPI: weeklySkidKPIReducer,
    weeklySkidRecords: weeklySkidRecordsReducer,
    purchaseOrder: purchaseOrderReducer,
    carriers: carriersReducer,
    skidDimensions: skidDimensionsReducer,
    clients: clientReducer,
    productTypes: productTypesReducer,
    allUsers: manageUsersReducer,
    processingInvoiceSkids: processingInvoiceSkidsReducer,
    bufferList: bufferReducer,
    skidsForProductTransfer: productTranferReducer,
    productStatusData: productStatusDataReducer,
    skidStatusData: skidStatusDataReducer,
    receivingInvoiceSkids: receivingInvoiceSkidsReducer,
    receivedSkidsBarcodes: reportReducer,
    bussRepListWithPO: businssRepListWithPOReducer,
    productsTableData: productsTableDataReducer,
    productTypeList: producctTypeListReducer,
    //====================quick book====================
    quickBookToken: quickBookTokenReducer,


    //====================price management====================
    priceList: priceReducer



});
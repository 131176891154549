import { Button, Divider, Pagination, Tag, Timeline } from 'antd';
import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';

export const TransactionTimelineUI = (params) => {
    const user = useSelector(state => state.authenticated);
    const history = useHistory();
    const [data, setdata] = useState({});
    const [totalCount, settotalCount] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(undefined);
    const [loading, setloading] = useState(true);

    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "admin" && user.role !== "businessRep")) {
            history.push('/');
        } else {
            getTransationTimeLineData(1);
        }
    }, []);

    const getTransationTimeLineData = async (pageNumber) => {
        try {
            setloading(true);
            const options = HEADER_OPTIONS_AUTH(user.token);
            await axios.get(`${BASE_URL}/getRecentDatesRecordByPage/${pageNumber}`, options)
                .then(function (response) {
                    setCurrentPage(pageNumber);
                    settotalCount(response.data.data.totalCount);
                    formatData(response.data.data.rows)
                })
                .catch(function (error) {
                    setloading(false);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });

        } catch (e) {
            console.log("error! " + e);
        }
    }

    const formatData = async (data) => {
        let formattedData = {};
        data.map(row => {
            if (!formattedData.hasOwnProperty(row.processDate)) {
                formattedData[row.processDate] = [];
            }
            let newRow = { ...row };
            newRow["loading"] = false;
            formattedData[row.processDate].push(newRow);
        })
        setdata(formattedData);
        setloading(false);
    }

    const changePageNumber = (pageNumber) => {
        getTransationTimeLineData(pageNumber);
    }

    const closeSkid = async (row, key, index) => {
        try {
            const options = HEADER_OPTIONS_AUTH(user.token);
            await axios.get(`${BASE_URL}/closeSkid/${row.skidID}/${row.skidBarCode}`, options)
                .then(function (response) {
                    let newData = { ...data };
                    newData[key][index].loading = false;
                    newData[key][index].skid_close_status = "Closed";
                    setdata(newData);
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            error.request
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                    let newData = { ...data };
                    newData[key][index].loading = false;
                    setdata(newData);

                });

        } catch (err) {
            console.log(err);
        }


    }

    return (
        <div>
            <h1 style={{ textAlign: 'center', marginBottom: "20px" }} >Transaction Timeline</h1>
            <Divider />
            <Timeline style={{ height: 800, padding: 10, overflowY: "scroll" }} mode="alternate" loading={loading}>
                {Object.keys(data).map((key, index) => (
                    <Timeline.Item color="green" key={index}>
                        <p key={index}>On {moment(key.substring(0, 10)).format('ddd MMMM DD YYYY')}</p>
                        {data[key].map((row, index) => (
                            row.itemStatus !== "processed" ? (
                                <p key={index}>System create <Tag color={"red"}>{row.count} </Tag>buffered products for {row.skidBarCode} during invoicing
                                </p>) : (
                                <p key={index}>We process {row.skidBarCode} with
                                    <Tag color={"green"}>{row.count}</Tag>
                          products
                                    {data[key][index].skid_close_status === "Open" ?

                                        <Button shape="round" type="primary" loading={data[key][index].loading} onClick={() => {
                                            let newData = { ...data };
                                            newData[key][index].loading = true
                                            setdata({ ...newData });
                                            closeSkid(row, key, index);
                                        }}>
                                            Close
                          </Button> : null}</p>
                            )
                        ))
                        }
                    </Timeline.Item>
                ))}
            </Timeline>
            <Pagination
                total={totalCount}
                current={currentPage}
                onChange={changePageNumber}
                visible={false}
                pageSize={10}
                showSizeChanger={false}
                style={{ marginTop: "15px" }}
                loading={loading}
            />

        </div>
    )


}
import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTable from 'react-table';
import { getItems, getPrices } from '../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from "../lib/utils";

export const TaskItem = (params) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authenticated);
    const processItems = useSelector(state => state.processItems);
    const companies = useSelector(state => state.companies);
    const priceList = useSelector(state => state.priceList);
    const [showDownload, setshowDownload] = useState(false);
    const [startDate, setstartDate] = useState(undefined);
    const [endDate, setendDate] = useState(undefined);
    const [optionList, setoptionList] = useState([]);
    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "admin" && user.role !== "businessRep")) {
            history.push('/');
        } else {
            getItems(user, dispatch);
            getPrices(user, dispatch);
        }
    }, []);

    useEffect(() => {
        if (companies) {
            let optionList = [];
            optionList.push(
                <option key="default" value="default">Choose Company For Receiving Invocie</option>
            )
            companies.forEach((company) => {
                optionList.push(
                    <option key={company.companyName} value={company.companyName}>{company.companyName}</option>
                )
            })

            setoptionList(optionList);
        }
    }, [companies]);

    const handleDownloadShow = () => {
        setshowDownload(true);
    };
    const handleChangeStartDate = (date) => {
        setstartDate(date);
    };
    const handleChangeEndDate = (date) => {
        setendDate(date);
    };
    const handleStateOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]: value
        });
    };
    const handleClose = (close) => {
        setstartDate(undefined);
        setendDate(undefined);
        setshowDownload(false);
    };
    const handleDownloadReport = async () => {

        const options = HEADER_OPTIONS_AUTH(user.token);
        options.responseType = 'blob';
        const data = {
            "companyID": "2",
            "startDate": moment(startDate).format('YYYY-MM-DD'),
            "endDate": moment(endDate).format('YYYY-MM-DD'),
        };
        try {
            await axios.post(`${BASE_URL}/download-processed-item-report`, data, options)
                .then(function (response) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Inflators_processed_report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });

        } catch (e) {
            alert("Download report failed!")
        }

    };

    const columns = [{
        Header: 'COMPANY NAME',
        accessor: 'companyName',
        filterMethod: (filter, row) =>
            row[filter.id].startsWith(filter.value)
    }, {
        Header: 'BAR CODE',
        accessor: 'barCode',
        filterMethod: (filter, row) =>
            row[filter.id].startsWith(filter.value)
    },
    {
        Header: 'RECEIVED DATE',
        id: 'receivedDate',
        accessor: row => (row.receivedDate !== undefined && row.receivedDate !== '0000-00-00') ? `${row.receivedDate.slice(0, 10)}` : '',
        filterMethod: (filter, row) =>
            row[filter.id].startsWith(filter.value)
    },
    {
        Header: 'PROCESSED DATE',
        id: 'processDate',
        accessor: row => (row.processDate !== undefined && row.processDate !== '0000-00-00') ? `${row.processDate.slice(0, 10)}` : 'NOT RECEIVE',
        filterMethod: (filter, row) =>
            row[filter.id].startsWith(filter.value)
    },
    {
        Header: 'STATUS',
        id: 'status',
        //accessor: 'status',
        accessor: row => (row.status === 'UNSUCCESSFULLY_DEACTIVATED') ? 'FAILED' : row.status,
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            if (filter.value === "DEACTIVATED") {
                //Staples.ca is the value show on the table
                return row[filter.id] === "DEACTIVATED";
            }
            if (filter.value === "SUCCESSFULLY_DEACTIVATED") {
                return row[filter.id] === "SUCCESSFULLY_DEACTIVATED";
            }
            if (filter.value === "FAILED") {
                return row[filter.id] === "FAILED";
            }
            return row[filter.id] === "Can't find role";
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value="SUCCESSFULLY_DEACTIVATED">SUCCESSFULLY DEACTIVATED</option>
                <option value="FAILED">FAILED</option>
            </select>
    },
    {
        Header: 'Billing Status',
        id: 'billing_status',
        //accessor: 'status',
        accessor: row => row.billing_status,
        filterMethod: (filter, row) => {
            if (filter.value === "all") {
                return true;
            }
            if (filter.value === "READY_FOR_INVOICE") {
                //Staples.ca is the value show on the table
                return row[filter.id] === "READY_FOR_INVOICE";
            }
            if (filter.value === "INVOICED") {
                return row[filter.id] === "INVOICED";
            }
            return row[filter.id] === "Can't find role";
        },
        Filter: ({ filter, onChange }) =>
            <select
                onChange={event => onChange(event.target.value)}
                style={{ width: "100%" }}
                value={filter ? filter.value : "all"}
            >
                <option value="all">Show All</option>
                <option value="READY_FOR_INVOICE">READY_FOR_INVOICE</option>
                <option value="INVOICED">INVOICED</option>
            </select>
    },
    ];

    return (
        <div style={{ textAlign: 'center' }}>
            {
                (user.role === "admin" || user.role === "operator") ?
                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{ marginBottom: '0' }}>
                        <Col sm="12">
                            <h1>Item Record</h1>
                        </Col>
                        {/* <Form.Label column sm="2">
                                <Button variant="success" onClick={this.handleDownloadShow}>Download Report</Button>
                            </Form.Label> */}
                    </Form.Group>
                    : <h1>Item Record</h1>
            }

            <Modal backdrop="static" show={showDownload} onHide={() => handleClose('showDownload')} centered dialogClassName={'user-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>Process Record Download</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive bordered={true} style={{ marginBottom: '0px' }}>
                        <tbody>
                            <tr>
                                <th>Start Date</th>
                                <td>
                                    <DatePicker
                                        selected={startDate === undefined ? null : startDate}
                                        onChange={handleChangeStartDate}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>End Date</th>
                                <td>
                                    <DatePicker
                                        selected={endDate === undefined ? null : endDate}
                                        onChange={handleChangeEndDate}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose('showDownload')}>
                        Close
                        </Button>
                    <Button variant="primary" onClick={handleDownloadReport}>
                        Download Report
                        </Button>
                </Modal.Footer>
            </Modal>


            {
                processItems.length !== undefined &&
                <ReactTable
                    data={processItems}
                    filterable
                    minRows={1}
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    columns={columns}
                />
            }
        </div>
    )

}

import { message, Pagination, Table } from 'antd';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';

export const InvoiceHistoryUI = (params) => {

    const user = useSelector(state => state.authenticated);
    const [invoiceHistoryList, setinvoiceHistoryList] = useState([]);
    const [totalCount, settotalCount] = useState(undefined);
    const [loading, setloading] = useState(false);
    const [loadingModal, setloadingModal] = useState(false);
    const [pageSize, setpageSize] = useState(20);
    const [showInvoiceDetail, setshowInvoiceDetail] = useState(false);
    const [showSendInvoice, setshowSendInvoice] = useState(false);
    const [isGroupSubmit, setisGroupSubmit] = useState(false);
    const [input, setinput] = useState(undefined);
    const [selectedInvoice, setselectedInvoice] = useState(undefined);
    const [invoiceDetail, setinvoiceDetail] = useState(undefined);
    const [selectedInvoiceList, setselectedInvoiceList] = useState([]);
    const [columns, setcolumns] = useState([
        {
            title: 'CompanyName    ',
            dataIndex: 'companyName',

            onFilter: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        }, {
            title: 'Invoice#',
            dataIndex: 'invoiceNumber',

            onFilter: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        // {
        //     title: 'From',
        //     dataIndex: 'skidBarCode',
        //     width: 200,
        //     onFilter: (filter, row) =>
        //         row[filter.id].startsWith(filter.value)
        // },
        {
            title: 'InvoiceDate',
            dataIndex: 'invoiceDate',

            render: (invoice_date) => { return ((invoice_date !== undefined && invoice_date !== '0000-00-00' && moment(invoice_date).isValid()) ? (<p>{moment(invoice_date).format("YYYY-MM-DD")}</p>) : (<p></p>)) },
            onFilter: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            title: 'BalanceDue',
            dataIndex: 'balanceDue',

        },
        {
            title: 'InvoiceType',
            dataIndex: 'InvoiceType',

            render: (pro) => {
                if (pro === "Process Airbags Invoice") {
                    return (
                        <span>Processing Invoice</span>
                    )
                } else if (pro === "Receive Skid Invoice") {
                    return (
                        <span>Receiving Invoice</span>
                    )
                }
                else {
                    return (
                        <span>{pro}</span>
                    )
                }
            }
        },
        {
            title: 'InvoiceDetail',
            sortable: false,
            filterable: false,
            render: (text, pro) => (
                <div>
                    <Button variant="info" size="sm block" onClick={() => handleShow(pro)}>
                        Show
                    </Button>
                    <Button style={{ marginLeft: '5px' }} variant="danger" size="sm block" onClick={() => handleShowSendInvoice(pro)}>
                        SendInvoice
                    </Button>
                </div>)
        },

        // {
        //     title: 'Delete',
        //     sortable: false,
        //     width: 200,
        //     filterable: false,
        //     render: (text, pro) =>
        //     (<div>
        //         <Popconfirm
        //             title={`Do you confirm to Delete the invoice?`}
        //             onConfirm={() => {

        //             }}
        //             okText="Yes"
        //             cancelText="No"
        //         >
        //             <Button size="sm" variant="danger">Delete</Button>
        //         </Popconfirm>
        //     </div>)
        // },

    ]);

    useEffect(() => {
        if (invoiceHistoryList.length === 0) {
            getInvoiceData(20, 1);
        }
    }, [])
    const getInvoiceData = async (recordsPerPage, pageNumber) => {
        setpageSize(recordsPerPage);
        try {
            setloading(true);
            const options = HEADER_OPTIONS_AUTH(user.token);
            await axios.get(`${BASE_URL}/quickBookInvoicesByPage/${recordsPerPage}/${pageNumber}`, options)
                .then(function (response) {
                    //  setCurrentPage(pageNumber);
                    setselectedInvoiceList([]);
                    settotalCount(response.data.data.totalRecords);
                    setinvoiceHistoryList(response.data.data.rows);
                    setloading(false);
                })
                .catch(function (error) {
                    setloading(false);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });

        } catch (e) {
            console.log("error! " + e);
        }
    }

    //handle show invoice detail from quick book account
    const handleShow = async (e) => {
        message.loading('Showing Invoice...', 0)

        try {
            const data = {
                quickBookInvoiceId: e.quickBookId
            }

            await axios.post(`${BASE_URL}/getInvoiceDetail`, data, HEADER_OPTIONS_AUTH(user.token))
                .then(function (response) {
                    message.destroy()
                    const invoiceDetail = response.data.data.Invoice;
                    setselectedInvoice(e);
                    setinvoiceDetail(invoiceDetail);
                    setshowInvoiceDetail(true);
                })
                .catch(function (error) {
                    message.destroy()
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });

        } catch (e) {
            message.destroy()
            openNotificationWithIcon('error', `showing invoice failed ${e}`);
        }
    }

    const handleClose = (e) => {
        setshowInvoiceDetail(false);
        setshowSendInvoice(false);
        setisGroupSubmit(false);
    }

    const handleInputEmail = (e) => {
        setinput(e.target.value);
    }

    const handleShowSendInvoice = (e) => {
        setselectedInvoice(e);
        setisGroupSubmit(false);
        setshowSendInvoice(true);
    }

    //handle send invoice through email address
    const handleSendEmailSubmit = async () => {
        message.loading('Sending Invoice...', 0);
        setloadingModal(true);
        try {
            const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (input && (input === '' || !res.test(String(input).toLowerCase()))) {
                setloadingModal(false);
                openNotificationWithIcon('error', "Email Format is incorrect")
                message.destroy();
                return;
            }
            const data = {
                email: input,
                quickBookInvoiceId: selectedInvoice.quickBookId
            }
            await axios.post(`${BASE_URL}/sendInvoiceByEmail`, data, HEADER_OPTIONS_AUTH(user.token))
                .then(function (response) {
                    message.destroy()
                    setloadingModal(false);
                    openNotificationWithIcon('success', "This invoice has been sent to Email: " + input)
                    setshowSendInvoice(false);
                    setinput("");

                })
                .catch(function (error) {
                    message.destroy();
                    setloadingModal(false);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }

                    setshowSendInvoice(false);
                    setinput("");

                });
        } catch (error) {
            console.log(error);
            message.destroy();
            setloadingModal(false);
            openNotificationWithIcon('error', error);
            setshowSendInvoice(false);
            setinput("");
        }
    }

    const handleGroupInvoiceSend = async () => {
        message.loading('Sending Invoice...', 0)
        try {
            setloadingModal(true);
            const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (input && (input === '' || !res.test(String(input).toLowerCase()))) {
                setloadingModal(false);
                message.destroy();
                openNotificationWithIcon('error', "Email Format is incorrect")
                return;
            }
            let invoiceHistoryList = [...selectedInvoiceList];
            for (let invoiceHistory of invoiceHistoryList) {

                const data = {
                    email: input,
                    quickBookInvoiceId: invoiceHistory.quickBookId
                }
                await axios.post(`${BASE_URL}/sendInvoiceByEmail`, data, HEADER_OPTIONS_AUTH(user.token))
                    .then(function (response) {
                        openNotificationWithIcon('success', "Invoice have been sent to Email: " + input);
                    })
                    .catch(function (error) {
                        message.destroy();
                        setloadingModal(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon(
                                "error",
                                error.response.data.message
                            );
                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                        setshowSendInvoice(false);
                        setinput("");
                    });

            }
            setloadingModal(false);
            setshowSendInvoice(false);
            setinput("");

            message.destroy();

        } catch (error) {
            message.destroy();
            setloadingModal(false);
            console.log(error);
            openNotificationWithIcon('error', error);
            setshowSendInvoice(false);
            setinput("");
        }

    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setselectedInvoiceList(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    return (

        <Container style={{ maxWidth: "100%", padding: 0 }}>
            <div style={{ textAlign: 'center', marginBottom: "20px" }}>


                <h1 style={{ marginBottom: '35px' }}>Invoice History</h1>

                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    //   bordered
                    columns={columns}
                    pagination={false}
                    dataSource={invoiceHistoryList}
                    loading={loading}
                // size={"small"}
                />
                {
                    invoiceHistoryList !== undefined &&
                    <Pagination
                        total={totalCount}
                        defaultCurrent={1}
                        defaultPageSize={20}
                        pageSize={pageSize}
                        onChange={(page, pageSize) => getInvoiceData(pageSize, page)}
                        // onShowSizeChange={(current,size)=>getInvoiceData(size,1) }
                        showSizeChanger={true}
                        style={{ marginTop: "15px" }}
                    />
                }
                {invoiceDetail && invoiceDetail ? (
                    <Modal backdrop="static" show={showInvoiceDetail} onHide={() => handleClose()} centered size="lg" dialogClassName={'user-modal'} >
                        <Modal.Header closeButton>
                            <Modal.Title>Invoice#   {selectedInvoice.invoiceNumber} created on {invoiceDetail.TxnDate}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group as={Row}>
                                <Form.Label column sm="6">
                                    Company Name:
                                    </Form.Label>
                                <Col sm="6">
                                    <Form.Control name="companyName" value={selectedInvoice.companyName} readOnly />

                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="6">
                                    Billing Adress:
                                    </Form.Label>
                                <Col sm="6">
                                    <Form.Control name="Line1" value={invoiceDetail.BillAddr.Line1} readOnly />
                                    <Form.Control name="City" value={invoiceDetail.BillAddr.City} readOnly />
                                    <Form.Control name="CountrySubDivisionCode" value={invoiceDetail.BillAddr.CountrySubDivisionCode} readOnly />
                                    <Form.Control name="Country" value={invoiceDetail.BillAddr.Country} readOnly />
                                    <Form.Control name="PostalCode" value={invoiceDetail.BillAddr.PostalCode} readOnly />

                                </Col>
                            </Form.Group>
                            <Form.Label>
                                Items Line:
                                </Form.Label>
                            <Form>

                                {
                                    invoiceDetail.Line.map((line, index) => {
                                        if (invoiceDetail.Line.length > index + 1) {
                                            return <Form.Row key={index}>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Item Description</Form.Label>
                                                    <Form.Control value={line.Description} readOnly />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>Qty</Form.Label>
                                                    <Form.Control value={line.SalesItemLineDetail.Qty} readOnly />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>UnitPrice</Form.Label>
                                                    <Form.Control value={line.SalesItemLineDetail.UnitPrice} readOnly />
                                                </Form.Group>
                                                <Form.Group as={Col}>
                                                    <Form.Label>item Amount</Form.Label>
                                                    <Form.Control value={line.Amount} readOnly />
                                                </Form.Group>
                                            </Form.Row>
                                        }

                                    })
                                }


                            </Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm="6">
                                    Total Tax:
                                    </Form.Label>
                                <Col sm="6">
                                    <Form.Control name="totalTex" value={invoiceDetail.TxnTaxDetail.TotalTax} readOnly />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="6" style={{ color: "red", fontSize: "larger", fontWeight: "bold" }}>
                                    Total Amount:
                                    </Form.Label>
                                <Col sm="6">
                                    <Form.Control name="totalAmount" value={invoiceDetail.TotalAmt} readOnly style={{ color: "red", fontSize: "larger", fontWeight: "bold" }} />
                                </Col>
                            </Form.Group>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                                </Button>
                        </Modal.Footer>
                    </Modal>

                ) : <div></div>}
                {
                    <Modal backdrop="static" show={showSendInvoice} onHide={() => handleClose()} centered dialogClassName={'user-modal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Where do you want to send</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control placeholder="Email address" onChange={handleInputEmail} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Close
                                </Button>
                            {isGroupSubmit ?
                                <Button variant="primary" disabled={loadingModal} onClick={() => handleGroupInvoiceSend()}>
                                    Send All Selected
                                </Button>
                                :
                                <Button variant="primary" disabled={loadingModal} onClick={() => handleSendEmailSubmit()}>
                                    Submit
                                </Button>}
                        </Modal.Footer>
                    </Modal>
                }
            </div>
            <div style={{ float: "right" }}>
                <Button variant="success" disabled={selectedInvoiceList === undefined || selectedInvoiceList.length === 0} size="sm block" onClick={() => {
                    setshowSendInvoice(true); setisGroupSubmit(true)
                }}>
                    Send Selected Invoice
                </Button>
            </div>
        </Container>
    )
}

import { GET_CLIENTS } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {

        case GET_CLIENTS:
            return action.payload;

        default:
            return state;
    }
}
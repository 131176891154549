import { Button, Form, Input, Select, Tabs } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReactTable from 'react-table';
import { getAllUsers, upDateUser } from '../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';
import { CreateUser } from "./CreateUser";
const { TabPane } = Tabs;

export const ManageUser = (params) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const allUsers = useSelector(state => state.allUsers);
    const [activeTab, setactiveTab] = useState("1");


    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "admin")) {
            history.push('/');
        } else {
            getAllUsers(user, dispatch);
        }
    }, [])

    const changeTab = activeKey => {
        setactiveTab(activeKey);
    };

    const ManageUserTabs = () => {
        return (
            <Tabs
                type="card"
                centered
                activeKey={activeTab}
                onChange={changeTab}
            >
                <TabPane tab="List" key="1">
                    <ManageUserScreen />
                </TabPane>
                <TabPane tab="Create New" key="2">
                    <CreateUser />
                </TabPane>
            </Tabs>
        );
    };
    const ManageUserScreen = () => {
        const { Option } = Select;
        const [selectedUser, setselectedUser] = useState(undefined);
        const [selectedUserForUpdate, setselectedUserForUpdate] = useState(undefined);
        const [showEdit, setshowEdit] = useState(false);
        const [loading, setLoading] = useState(false);

        const handleSave = async () => {
            if (window.confirm('Do you confirm to update?')) {
                setLoading(true);
                const toUpdateUser = Object.assign({}, selectedUser);;
                delete toUpdateUser.companyName;

                await upDateUser(user, toUpdateUser, dispatch);
                setLoading(false);
                var userToUpdate = selectedUserForUpdate;
                userToUpdate.role = selectedUser.role;
                userToUpdate.firstName = selectedUser.firstName;
                userToUpdate.lastName = selectedUser.lastName;
                userToUpdate.email = selectedUser.email;
                userToUpdate.phone = selectedUser.phone;
                setselectedUserForUpdate(userToUpdate);
                setselectedUser(undefined);
                setshowEdit(false);

            }

        };
        const handleClose = () => {
            setselectedUser(undefined);
            setshowEdit(false);

        };

        const handleShow = (val) => {
            setselectedUserForUpdate(val);
            setselectedUser({ ...val });
            setshowEdit(true);

        };

        const handleFormValuesChange = changedValues => {
            const formFieldName = Object.keys(changedValues)[0];
            if (formFieldName === "role") {
                var user = selectedUser;
                user.role = changedValues[formFieldName];
                setselectedUser(user);
            }
            else if (formFieldName === "firstName") {
                var user = selectedUser;
                user.firstName = changedValues[formFieldName];
                setselectedUser(user);
            } else if (formFieldName === "lastName") {
                var user = selectedUser;
                user.lastName = changedValues[formFieldName];
                setselectedUser(user);
            }
            else if (formFieldName === "email") {
                var user = selectedUser;
                user.email = changedValues[formFieldName];
                setselectedUser(user);
            }
            else if (formFieldName === "phone") {
                var user = selectedUser;
                user.phone = changedValues[formFieldName];
                setselectedUser(user);
            }

        };

        const handleActivate = async (client) => {
            if (window.confirm('Do you confirm to Activate?')) {

                let data = {};
                data.userId = client.id;

                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.post(`${BASE_URL}/activateUser`, data, options)
                        .then(function (response) {
                            refreshPage(user);
                        })
                        .catch(function (error) {
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Activate Client due to ${error.response.data.message}`);

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        };

        const refreshPage = (user) => {
            getAllUsers(user, dispatch);
        }
        const handleDeactivate = async (val) => {
            if (window.confirm('Do you confirm to Deactivate?')) {

                let data = {};
                data.userId = val;

                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.post(`${BASE_URL}/deactivateUser`, data, options)
                        .then(function (response) {
                            refreshPage(user);
                        })
                        .catch(function (error) {
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Deactivate Client due to ${error.response.data.message}`);

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        };
        const columns = [{
            Header: 'ROLE',
            accessor: 'role',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } if (filter.value === "superAdmin") {
                    //Staples.ca is the value show on the table
                    return row[filter.id] === "superAdmin";
                }
                if (filter.value === "admin") {
                    //Staples.ca is the value show on the table
                    return row[filter.id] === "admin";
                }
                if (filter.value === "operator") {
                    return row[filter.id] === "operator";
                } if (filter.value === "businessRep") {
                    return row[filter.id] === "businessRep";
                }

                return row[filter.id] === "Can't find role";
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">Show All</option>
                    <option value="superAdmin">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="businessRep">Business Rep.</option>
                    <option value="operator">Operator</option>

                </select>
        }, {
            id: 'fullNAME',
            Header: 'FULL NAME',
            accessor: d => `${d.firstName}  ${d.lastName}`,
            // accessor: d => d.friend.name // Custom value accessors!
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        }, {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'EMAIL',
            accessor: 'email',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        }, {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'PHONE',
            accessor: 'phone',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: '',
            sortable: false,
            filterable: false,
            Cell: (pro) =>
                pro.original.activate === 1 ?
                    <Button type="danger" onClick={() => handleDeactivate(pro.original.id)}>
                        Deactivate
    </Button> :
                    <Button type="primary" onClick={() => handleActivate(pro.original)}>
                        Activate
    </Button>
        },
        {
            Header: 'EDIT',
            sortable: false,
            filterable: false,
            Cell: (pro) =>
                <Button type="primary" onClick={() => handleShow(pro.original)}>
                    Edit
    </Button>
        }];

        return (
            <div style={{ textAlign: 'center' }}>
                {
                    selectedUser !== undefined &&
                    <Modal backdrop="static" show={showEdit} onHide={handleClose} centered dialogClassName={'user-modal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Editing User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <Form
                                layout="horizontal"
                                size="medium"
                                className="manangeUserForm"
                                onFinish={handleSave}
                                initialValues={selectedUser}
                                onValuesChange={handleFormValuesChange}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 12 }}
                                style={{
                                    marginLeft: "5%", marginRight: "5%"
                                }}
                            >
                                <Form.Item
                                    label="Role:"
                                    name="role"
                                    value={selectedUser.role}
                                >
                                    {user.role === "superAdmin" ?
                                        <Select>
                                            <Option key="operator" value="operator">
                                                Operator
                                            </Option>
                                            <Option key="admin" value="admin">
                                                Admin
                                            </Option>
                                            <Option key="superAdmin" value="superAdmin">
                                                Super Admin
                                            </Option>

                                        </Select>
                                        : <Select>
                                            <Option key="operator" value="operator">
                                                Operator
                                            </Option>
                                            <Option key="admin" value="admin">
                                                Admin
                                            </Option>
                                        </Select>
                                    }
                                </Form.Item>
                                <Form.Item
                                    label="First Name:"
                                    name="firstName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input First Name!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Last Name:"
                                    name="lastName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Last Name!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Email:"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Email!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Phone:"
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Phone!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Button

                                    type="primary"
                                    htmlType="submit"
                                    style={{ float: "right", marginLeft: "12px" }}
                                    loading={loading}
                                >
                                    Save Changes
                </Button>
                                <Button
                                    type="secondary"
                                    style={{ float: "right" }}
                                    onClick={() => handleClose(false)}
                                >
                                    Close
                </Button>
                            </Form>

                        </Modal.Body>

                    </Modal>
                }

                {
                    allUsers.length !== undefined &&
                    <ReactTable
                        data={allUsers}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={columns}
                    />
                }
            </div>

        );
    }
    return (
        <div style={{ margin: "10px", textAlign: 'center' }}>
            <h1>Manage User</h1>

            <ManageUserTabs />
        </div>
    );

}

import { FrownTwoTone, SmileTwoTone } from '@ant-design/icons';
import { Card, Carousel, DatePicker, Divider, Progress } from 'antd';
import axios from 'axios';
import localStorage from 'local-storage';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Bar, Line, Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearAllKPI, getCompanies, getInflatorsInventory, getMonthlyKPI, getMonthlyRecords, getProductStatusData, getQuickBookToken, getSkidStatusData, getWeeklyKPI, getWeeklyRecords, getWeeklySkidKPI, getWeeklySkidRecords } from '../actions/index';
import KPIModal from './cells/kpiModal';
import { BASE_URL, HEADER_OPTIONS_AUTH, TEST_HOME_PAGE } from './lib/basic';
import { openNotificationWithIcon } from "./lib/utils";
import {
    CHANGE_AUTH, GET_ALL_PRICES, GET_COMPANY, GET_DAILY_KPI, GET_DAILY_SKID_KPI, GET_INFLATORS_INVENTORY, GET_ITEMS, GET_MONTHLY_KPI, GET_MONTHLY_RECORDS, GET_PRODUCTS, GET_QUICKBOOK_TOKEN, GET_SKIDS,
    GET_USERS, GET_WAREHOUSES, GET_WEEKLY_KPI, GET_WEEKLY_RECORDS, GET_WEEKLY_SKID_RECORDS
} from "./../actions/types";
import { LOGIN_PAGE } from "../components/lib/basic";

export const HomeUI = (params) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showKPI, setshowKPI] = useState(false);
    const user = useSelector(state => state.authenticated);

    const inflatorsInventory = useSelector(state => state.inflatorsInventory);
    let quickbookToken = useSelector(state => state.quickBookToken);
    const skidStatusData = useSelector(state => state.skidStatusData);
    const productStatusData = useSelector(state => state.productStatusData);

    const weeklyKPI = useSelector(state => state.weeklyKPI);
    const monthlyKPI = useSelector(state => state.monthlyKPI);
    const companies = useSelector(state => state.companies);

    const weeklyRecords = useSelector(state => state.weeklyRecords);
    const monthlyRecords = useSelector(state => state.monthlyRecords);

    const weeklySkidKPI = useSelector(state => state.weeklySkidKPI);
    const weeklySkidRecords = useSelector(state => state.weeklySkidRecords);
    const weeklyKPIData = {
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        datasets: [
            {
                label: "Target",
                fill: false,
                borderColor: 'rgb(202, 61, 204)',
                borderCapStyle: 'butt',
                borderDash: [],
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                data: weeklyKPI
            },
            {
                label: "Processed",
                backgroundColor: "rgb(61, 204, 147,0.3)",
                borderColor: 'rgb(61, 204, 147)',
                borderCapStyle: 'butt',
                borderDash: [],
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(61, 204, 147)',
                data: weeklyRecords
            }
        ]
    };
    const weeklyOptions = {
        title: {
            display: true,
            text: 'Weekly Products Process',
            fontSize: 20,
            padding: 20
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 15
            }
        },
    };
    const overallKPIData = {
        labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        datasets: [
            {
                label: "Target - K",
                backgroundColor: "#1a7eb0",
                data: monthlyKPI
            },
            {
                label: "Processed - K",
                backgroundColor: "#3dcc93",
                data: monthlyRecords
            }
        ]
    };
    const overallKPIOptions = {
        title: {
            display: true,
            text: 'Overall Product Process',
            fontSize: 20,
            padding: 20
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 15
            }
        }
    };


    const weeklySkidKPIData = {
        labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        datasets: [
            {
                label: "Target",
                fill: false,
                borderColor: 'rgb(202, 61, 204)',
                borderCapStyle: 'butt',
                borderDash: [],
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                data: weeklySkidKPI
            },
            {
                label: "Processed",
                backgroundColor: "rgb(61, 204, 147,0.3)",
                borderColor: 'rgb(61, 204, 147)',
                borderCapStyle: 'butt',
                borderDash: [],
                pointBorderWidth: 2,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgb(61, 204, 147)',
                data: weeklySkidRecords
            }
        ]
    };
    const weeklySkidOptions = {
        title: {
            display: true,
            text: 'Weekly Skid Process',
            fontSize: 20,
            padding: 20
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontSize: 15
            }
        },
    };
    const overallInflatorsInventoryData = {
        labels: ["Received at 3PL", "Received at Lacero", "Processed Product", "Unprocessed Product"],
        datasets: [
            {
                label: "Received at 3PL",
                backgroundColor: "#1e79b0",
                data: [inflatorsInventory.received3PL]
            },
            {
                label: "Received at Lacero",
                backgroundColor: "#169ecc",
                data: [0, inflatorsInventory.receivedLacero]
            },
            {
                label: "Processed Product",
                backgroundColor: "#11b066",
                data: [0, 0, inflatorsInventory.processed]
            },
            {
                label: "Unprocessed Product",
                backgroundColor: "#fbff17",
                data: [0, 0, 0, inflatorsInventory.unProcess]
            }
        ]
    };
    const overallInflatorsInventoryOptions = {
        title: {
            display: true,
            text: 'Overall Product Inventory',
            fontSize: 20,
            padding: 20
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        maxTicksLimit: 7,
                        display: false, //this removed the labels on the x-axis
                    },
                    'dataset.maxBarThickness': 45,
                },
            ]
        },

        legend: {
            display: true,
            position: 'right',
            labels: {
                fontSize: 15
            }
        }
    };


    useEffect(() => {
        async function fetchData() {
            if (!user) {
                history.push('/');
            } else {
                await validateLogin(user);

            }
        }
        fetchData();
    }, [])

    const validateLogin = async (user) => {
        console.log("Validating Login...");
        await axios.get(`${BASE_URL}/validateLogin`, HEADER_OPTIONS_AUTH(user.token))
            .then(async function (response) {
                await getQuickbookToken();
                getCompanies(user, dispatch);
            })
            .catch(async function (error) {
                //Logout

                if (error.response) {
                    // Request made and server responded
                    openNotificationWithIcon(
                        "error",
                        error.response.data.message
                    );
                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
                console.log(error);
                await logOut();
                window.location.replace(LOGIN_PAGE);
            });


    }

    const logOut = async () => {

        dispatch({ type: CHANGE_AUTH, payload: false });
        dispatch({ type: GET_USERS, payload: [] });
        dispatch({ type: GET_SKIDS, payload: [] });
        dispatch({ type: GET_WAREHOUSES, payload: [] });
        dispatch({ type: GET_ITEMS, payload: [] });
        dispatch({ type: GET_WEEKLY_KPI, payload: [] });
        dispatch({ type: GET_MONTHLY_KPI, payload: [] });
        dispatch({ type: GET_COMPANY, payload: [] });
        dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
        dispatch({ type: GET_DAILY_KPI, payload: [] });
        dispatch({ type: GET_MONTHLY_RECORDS, payload: [] });
        dispatch({ type: GET_INFLATORS_INVENTORY, payload: [] });
        dispatch({ type: GET_DAILY_SKID_KPI, payload: [] });
        dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: [] });
        dispatch({ type: GET_QUICKBOOK_TOKEN, payload: [] });
        dispatch({ type: GET_PRODUCTS, payload: [] });
        dispatch({ type: GET_ALL_PRICES, payload: [] });
        //clean localstorage
        localStorage.remove("AuthorizationUrl");
        localStorage.remove("Token");
        localStorage.remove("oauthTokenSecret");
        localStorage.remove("timer");

    }

    const getQuickbookToken = async () => {

        if (user.role === "superAdmin") {
            // const authurl=localStorage.get('AuthorizationUrl');

            //first time login system
            // QB Login for the admin
            if (!localStorage.get('Token') && window.location.href === `${TEST_HOME_PAGE}`) {

                await axios.get(`${BASE_URL}/quickBookAuthorizationUrl`, HEADER_OPTIONS_AUTH(user.token))
                    .then(function (response) {

                        const quickBookAuthUrl = response.data.data;
                        //check logined status in quick book
                        window.location.replace(quickBookAuthUrl)

                    })
                    .catch(function (error) {
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon(
                                "error",
                                error.response.data.message
                            );
                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });

            }
            if (window.location.href !== `${TEST_HOME_PAGE}` && !localStorage.get('AuthorizationUrl')) {
                localStorage.set("AuthorizationUrl", window.location.href);
            }
            if (!localStorage.get('Token') && window.location.href !== `${TEST_HOME_PAGE}`) {

                const aurthorizedURLcode = window.location.href;
                //get token from quickBook
                await getQuickBookToken(user, aurthorizedURLcode, dispatch);

                localStorage.set("Token", quickbookToken);
                callPieChartAPIs(user);
            }
        } else {
            callPieChartAPIs(user);
        }
    }

    const callPieChartAPIs = async () => {
        // console.log("Inside Call Pie charts APIs!");
        getCompanies(user, dispatch);
        getProductStatusData(user, dispatch);
        getSkidStatusData(user, dispatch);
    }

    const handleSelectCompanyOnchange = async (e) => {
        const companyID = e.target.value;
        if (companyID === "") {
            clearAllKPI();
        } else {

            const now = new Date().toISOString().slice(0, 10);
            getWeeklyKPI(user, now, companyID, dispatch);
            getWeeklySkidKPI(user, now, companyID, dispatch);
            getMonthlyKPI(user, now, companyID, dispatch);
            getWeeklyRecords(user, now, companyID, dispatch);
            getWeeklySkidRecords(user, now, companyID, dispatch);
            getMonthlyRecords(user, now, companyID, dispatch);
            getInflatorsInventory(user, dispatch);
        }
    };
    const handleCloseKPI = () => {
        setshowKPI(false);
    };


    return (
        <Container style={{ textAlign: 'center' }} >
            <KPIModal show={showKPI} close={handleCloseKPI} />
            <h1 style={{ margin: '4vh 0', color: 'black' }}>Dash Board</h1>
            <Row>
                <Col>
                    <Pie
                        data={{
                            labels: ['In Transition', 'Received', 'Invoiced'],
                            datasets: [{
                                data: [skidStatusData.UN_INVOICE, skidStatusData.READY_FOR_INVOICE, skidStatusData.INVOICED],
                                backgroundColor: ['blue', '#fc3838', '#03fc07']
                            }]
                        }}
                        loading={skidStatusData === null}
                        options={{
                            title: {
                                display: true,
                                text: 'Skid Status',
                                fontSize: 20,
                                padding: 20
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    fontSize: 15
                                }
                            }
                        }}
                    />
                </Col>
                <Col>
                    <Pie
                        data={{
                            labels: ['Processed', 'Invoiced'],
                            datasets: [{
                                data: [productStatusData.READY_FOR_INVOICE, productStatusData.INVOICED],
                                backgroundColor: ['#fc3838', '#03fc07']
                            }]
                        }}
                        loading={productStatusData === null}
                        options={{
                            title: {
                                display: true,
                                text: 'Product Status',
                                fontSize: 20,
                                padding: 20
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    fontSize: 15
                                }
                            }
                        }}
                    />
                </Col>
            </Row>
            <br />
            <Row>
                <Col>
                    <Progress
                        percent={Math.round((skidStatusData.INVOICED / (skidStatusData.INVOICED + skidStatusData.UN_INVOICE + skidStatusData.READY_FOR_INVOICE)) * 100)}
                        status={Math.round((skidStatusData.INVOICED / (skidStatusData.INVOICED + skidStatusData.UN_INVOICE + skidStatusData.READY_FOR_INVOICE)) * 100) === 100 ? "" : "active"}
                    />
                </Col>
                <Col>
                    <Progress
                        percent={Math.round((productStatusData.INVOICED / (productStatusData.INVOICED + productStatusData.READY_FOR_INVOICE)) * 100)}
                        status={Math.round((productStatusData.INVOICED / (productStatusData.INVOICED + productStatusData.READY_FOR_INVOICE)) * 100) === 100 ? "" : "active"}
                    />
                </Col>

            </Row>
            <Divider />
            <Carousel autoplay speed={250} autoplaySpeed={5000}>

                <div>
                    <Row>
                        <Col>
                            <Card title="Skid Inventory Report" hoverable >
                                <p>Total Received Skids: {skidStatusData.TOTAL}</p>
                                <p> <SmileTwoTone twoToneColor="#52c41a" /> Invoiced Skid: {skidStatusData.INVOICED}</p>
                                <p> <FrownTwoTone twoToneColor="red" /> Not Invoiced Skid: {skidStatusData.READY_FOR_INVOICE}</p>
                            </Card>
                        </Col>
                        <Col>
                            <Card title="Products inventory Report" hoverable  >
                                <p>Total Received Product: {productStatusData.TOTAL}</p>
                                <p> <SmileTwoTone twoToneColor="#52c41a" /> Invoiced Product: {productStatusData.INVOICED}</p>
                                <p> <FrownTwoTone theme="twoTone" twoToneColor="red" /> Not Invoiced Product: {productStatusData.READY_FOR_INVOICE}</p>
                            </Card>
                        </Col>


                    </Row>
                </div>

            </Carousel>
            <Divider />


            <Row>
                <Col>
                    <h3 style={{ color: 'black' }}>Company KPI</h3>
                </Col>

                {(companies.length !== undefined && companies.length > 0) &&
                    <Col>
                        <Form.Control as="select" onChange={handleSelectCompanyOnchange} >
                            <option value={""}>--- Please Company to view KPI ---</option>
                            {
                                companies.map((company, index) => {
                                    return <option key={index} value={company.id}>{company.companyName}</option>
                                })
                            }
                            <option value={"undefined"}>--- Show All ---</option>
                        </Form.Control>
                    </Col>
                }
                { /* <Col>
                                   <Button variant="primary" onClick={()=>{this.setState({showKPI:true})}}>
                                       CHECK KPI BY RANGE
                                   </Button>
                               </Col>*/}
            </Row>

            <br />
            <Row>
                <Col>
                    <label htmlFor="week">Select a week:</label>
                    <DatePicker label="Select a week: " picker="week" />
                    <Line data={weeklyKPIData} options={weeklyOptions} />
                </Col>
                <Col>
                    <Bar data={overallKPIData} options={overallKPIOptions} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Line data={weeklySkidKPIData} options={weeklySkidOptions} />
                </Col>
                <Col>
                    <Bar data={overallInflatorsInventoryData} options={overallInflatorsInventoryOptions} />
                </Col>
            </Row>
        </Container>
        // }else {
        //     return(
        //         <Container style={{ textAlign: 'center' }}>
        //             <KPIModal show={this.state.showKPI} close={this.handleCloseKPI} />
        //             <h1 style={{ margin: '4vh 0', color: 'Black' }}>Dash Board</h1>
        //             <Row>
        //                 <Col>
        //                     <Pie
        //                         data={{
        //                             labels: ['In Transition', 'Received', 'Invoiced'],
        //                             datasets: [{
        //                                 data: [skidStatusData.UN_INVOICE, skidStatusData.READY_FOR_INVOICE, skidStatusData.INVOICED],
        //                                 backgroundColor: ['blue', '#fc3838', '#03fc07']
        //                             }]
        //                         }}
        //                         loading={skidStatusData === null}

        //                         options={{
        //                             title: {
        //                                 display: true,
        //                                 text: 'Skid Status',
        //                                 fontSize: 20,
        //                                 padding: 20
        //                             },
        //                             legend: {
        //                                 display: true,
        //                                 position: 'bottom',
        //                                 labels: {
        //                                     fontSize: 15
        //                                 }
        //                             }
        //                         }}
        //                     />
        //                 </Col>
        //                 <Col>
        //                     <Pie
        //                         data={{
        //                             labels: ['Processed', 'Invoiced'],
        //                             datasets: [{
        //                                 data: [productStatusData.READY_FOR_INVOICE, productStatusData.INVOICED],
        //                                 backgroundColor: ['#fc3838', '#03fc07']
        //                             }]
        //                         }}
        //                         loading={productStatusData === null}
        //                         options={{
        //                             title: {
        //                                 display: true,
        //                                 text: 'Product Status',
        //                                 fontSize: 20,
        //                                 padding: 20
        //                             },
        //                             legend: {
        //                                 display: true,
        //                                 position: 'bottom',
        //                                 labels: {
        //                                     fontSize: 15
        //                                 }
        //                             }
        //                         }}
        //                     />
        //                 </Col>
        //             </Row>
        //             <br />
        //             <Row>
        //                 <Col>
        //                     <Progress
        //                         percent={Math.round((skidStatusData.INVOICED / (skidStatusData.INVOICED + skidStatusData.UN_INVOICE + skidStatusData.READY_FOR_INVOICE)) * 100)}
        //                         status={Math.round((skidStatusData.INVOICED / (skidStatusData.INVOICED + skidStatusData.UN_INVOICE + skidStatusData.READY_FOR_INVOICE)) * 100) === 100 ? "" : "active"}
        //                     />
        //                 </Col>
        //                 <Col>
        //                     <Progress
        //                         percent={Math.round((productStatusData.INVOICED / (productStatusData.INVOICED + productStatusData.READY_FOR_INVOICE)) * 100)}
        //                         status={Math.round((productStatusData.INVOICED / (productStatusData.INVOICED + productStatusData.READY_FOR_INVOICE)) * 100) === 100 ? "" : "active"}
        //                     />
        //                 </Col>


        //             </Row>
        //             <Divider />
        //             <Carousel speed={250} autoplaySpeed={5000} autoplay>
        //                 <div>
        //                     <Row>
        //                         <Col>
        //                             <Card title="Skid Inventory Report" hoverable >
        //                                 <p>Total Received Skids: {skidStatusData.TOTAL}</p>
        //                                 <p> <SmileTwoTone twoToneColor="#52c41a" /> Invoiced Skid: {skidStatusData.INVOICED}</p>
        //                                 <p> <FrownTwoTone twoToneColor="red" /> Not Invoiced Skid: {skidStatusData.READY_FOR_INVOICE}</p>
        //                             </Card>
        //                         </Col>
        //                         <Col>
        //                             <Card title="Products inventory Report" hoverable  >
        //                                 <p>Total Received Product: {productStatusData.TOTAL}</p>
        //                                 <p> <SmileTwoTone twoToneColor="#52c41a" /> Invoiced Product: {productStatusData.INVOICED}</p>
        //                                 <p> <FrownTwoTone theme="twoTone" twoToneColor="red" /> Not Invoiced Product: {productStatusData.READY_FOR_INVOICE}</p>
        //                             </Card>
        //                         </Col>

        //                     </Row>
        //                 </div>
        //             </Carousel>

        //             <Divider />

        //             <Row>
        //                 <Col>
        //                     <h3 style={{ color: 'black' }}>Company KPI</h3>
        //                 </Col>
        //                 {(companies.length !== undefined && companies.length > 0) &&
        //                     <Col>
        //                         <Form.Control as="select" onChange={this.handleSelectCompanyOnchange} >
        //                             <option value={""}>--- Please Company to view KPI ---</option>
        //                             {
        //                                 companies.map((company, index) => {
        //                                     return <option key={index} value={company.id}>{company.companyName}</option>
        //                                 })
        //                             }
        //                             <option value={"undefined"}>--- Show All ---</option>
        //                         </Form.Control>
        //                     </Col>
        //                 }
        //                 {   /* <Col>
        //                                    <Button variant="primary" onClick={()=>{this.setState({showKPI:true})}}>
        //                                        CHECK KPI BY RANGE
        //                                    </Button>
        //                                </Col>*/}
        //             </Row>
        //         </Container>);
        //                }
        //            }else {
        //     return (
        //         <Container style={{ textAlign: 'center' }}>
        //             <h1 style={{ margin: '8vh 0', color: 'rgb(88, 88, 88, 0.8)' }}>Dash Board</h1>
        //             <Row>
        //                 <Col>
        //                     <Pie
        //                         data={{
        //                             labels: ['In Transition', 'Received', 'Invoiced'],
        //                             datasets: [{
        //                                 data: [skidStatusData.UN_INVOICE, skidStatusData.READY_FOR_INVOICE, skidStatusData.INVOICED],
        //                                 backgroundColor: ['blue', '#fc3838', '#03fc07']
        //                             }]
        //                         }}
        //                         loading={skidStatusData === null}

        //                         options={{
        //                             title: {
        //                                 display: true,
        //                                 text: 'Skid Status',
        //                                 fontSize: 20,
        //                                 padding: 20
        //                             },
        //                             legend: {
        //                                 display: true,
        //                                 position: 'bottom',
        //                                 labels: {
        //                                     fontSize: 15
        //                                 }
        //                             }
        //                         }}
        //                     />
        //                 </Col>
        //                 <Col>
        //                     <Pie
        //                         data={{
        //                             labels: ['Processed', 'Invoiced'],
        //                             datasets: [{
        //                                 data: [productStatusData.READY_FOR_INVOICE, productStatusData.INVOICED],
        //                                 backgroundColor: ['#fc3838', '#03fc07']
        //                             }]
        //                         }}
        //                         loading={productStatusData === null}
        //                         options={{
        //                             title: {
        //                                 display: true,
        //                                 text: 'Item Status',
        //                                 fontSize: 20,
        //                                 padding: 20
        //                             },
        //                             legend: {
        //                                 display: true,
        //                                 position: 'bottom',
        //                                 labels: {
        //                                     fontSize: 15
        //                                 }
        //                             }
        //                         }}
        //                     />
        //                 </Col>
        //             </Row>
        //         </Container>
    )
}

import {
  Button as AntdButton,
  Card, Form as AntdForm,
  InputNumber,
  Row as AntdRow,
  Col as AntdCol,
  Popconfirm,
} from "antd";
import axios from "axios";
import localStorage from "local-storage";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Form,
  Modal,
  Row, Table
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import { deleteDailySkidKPI, getPrices, getProducts, getSkidDimensions, getSkids, getUsers, getWarehouse } from '../../actions/index';
import { CREATE_SKID, UPDATE_SKID } from '../../actions/types';
import {
  BASE_URL,
  getSKIDTaskStartNumber,
  HEADER_OPTIONS_AUTH
} from "../lib/basic";
import { openNotificationWithIcon } from "../lib/utils";



export const TaskSkid = (params) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const allusers = useSelector(state => state.users);
  const skids = useSelector(state => state.skids);
  const warehouses = useSelector(state => state.warehouses);
  const companies = useSelector(state => state.companies);
  const quickBookToken = useSelector(state => state.quickBookToken);
  const priceList = useSelector(state => state.priceList);
  const products = useSelector(state => state.products);
  const allskidDimensions = useSelector(state => state.skidDimensions);

  const [showEditing, setshowEditing] = useState(false);
  const [selectedSkid, setselectedSkid] = useState(undefined);
  const [showCreate, setshowCreate] = useState(false);
  const [showBatchUpdate, setshowBatchUpdate] = useState(false);
  const [showInvoiceForm, setshowInvoiceForm] = useState(false);
  const [showDownload, setshowDownload] = useState(false);
  const [showUpdateCount, setshowUpdateCount] = useState(false);
  const [deleteLoading, setdeleteLoading] = useState(false);
  const [restoreLoading, setrestoreLoading] = useState(false);

  const [optionList, setoptionList] = useState([]);
  const [productNameMap, setproductNameMap] = useState(undefined);
  const [skidDimensions, setskidDimensions] = useState(undefined);
  const [users, setusers] = useState([]);
  const [companyNames, setcompanyNames] = useState([]);

  const [newSKID, setnewSKID] = useState({});
  const [updateBasic, setupdateBasic] = useState(false);
  const [origin, setorigin] = useState("");
  const [weight, setweight] = useState("");
  const [length, setlength] = useState("");
  const [width, setwidth] = useState("");
  const [height, setheight] = useState("");
  const [units, setunits] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [locatedWarehouseID, setlocatedWarehouseID] = useState("");
  const [status, setstatus] = useState("");
  const [process_status, setprocess_status] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [receivedDate, setreceivedDate] = useState(undefined);
  const [batchStartRecord, setbatchStartRecord] = useState([]);
  const [batchEndRecord, setbatchEndRecord] = useState([]);
  const [startDate, setstartDate] = useState(undefined);
  const [endDate, setendDate] = useState(undefined);

  useEffect(() => {
    if (!user || (user.role !== "superAdmin" && user.role !== "admin" && user.role !== "businessRep")) {
      history.push('/');
    } else {
      getSkids(user, dispatch);
      getUsers(user, dispatch);
      getPrices(user, dispatch);
      getWarehouse(user, dispatch);
      getProducts(user, dispatch);
      getSkidDimensions(user, dispatch);
    }
  }, [])

  useEffect(() => {
    if (allskidDimensions) {
      setskidDimensions(allskidDimensions[0]);
    }
  }, [allskidDimensions])

  useEffect(() => {
    if (allusers) {
      let filteredUsers = allusers.filter(
        user => user.companyName !== "LACERO" && user.role === "client"
      )
      setusers(filteredUsers);
      let companyNamesArray = [];
      filteredUsers.forEach((user, index) => {
        if (companyNamesArray.indexOf(user.companyName) === -1) {
          companyNamesArray.push(user.companyName);
        }
      });
      setcompanyNames(companyNamesArray);
    }
  }, [allusers])


  useEffect(() => {
    if (products) {
      let productNameMapJson = {};
      products.forEach(product => {
        let id = product.id;
        productNameMapJson[id] = product.productName;
      });
      setproductNameMap(productNameMapJson);
    }
  }, [products])

  useEffect(() => {
    if (companies) {
      let optionList = [];
      optionList.push(
        <option key="default" value="default">
          Choose Company For Receiving Invocie
        </option>
      );
      companies.forEach(company => {
        optionList.push(
          <option key={company.companyName} value={company.companyName}>
            {company.companyName}
          </option>
        );
      });
      setoptionList(optionList);
    }
  }, [companies]);

  const handleEditSave = async () => {
    if (window.confirm("Do you confirm to update?")) {
      let copyselectedSkid = { ...selectedSkid };
      copyselectedSkid.origin = copyselectedSkid.origin.toUpperCase();
      // if(selectedSkid.status === "READY_FOR_INVOICE"){
      //     selectedSkid.receivedDate = moment(this.state.receivedDate).format('YYYY-MM-DD');
      // }
      if (receivedDate) {
        copyselectedSkid.receivedDate = moment(receivedDate).format(
          "YYYY-MM-DD"
        );
      }
      const toUpdateSkid = Object.assign({}, copyselectedSkid);
      delete toUpdateSkid.companyName;
      await upDateSkid(user, toUpdateSkid, copyselectedSkid);
    }
  };

  const upDateSkid = async (user, skid, row) => {
    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      const response = await axios.put(`${BASE_URL}/skid`, skid, options)
        .then(function (response) {
          setselectedSkid(undefined);
          setshowEditing(false);
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
            dispatch({ type: UPDATE_SKID, payload: [] });
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });


    }
    catch (e) {
      openNotificationWithIcon(
        "error",
        e);
    }
  };

  const handleClose = close => {
    setselectedSkid(undefined);
    setreceivedDate(undefined);
    if (close === "showUpdateCount") {
      setshowUpdateCount(false);
    } else if (close === "showEditing") {
      setshowEditing(false);
    } else if (close === "showCreate") {
      setshowCreate(false);
    } else if (close === "showInvoiceForm") {
      setshowInvoiceForm(false);
    } else if (close === "showBatchUpdate") {
      setshowBatchUpdate(false);
    }
  };

  const handleEditShow = val => {
    console.log(val);
    let skid = { ...val }
    skid["updateBasic"] = skid.process_status !== "UN_RECEIVE" ? true : false;
    setselectedSkid(skid);
    setshowEditing(true);
  };
  const handleCreateShow = () => {
    setshowCreate(true);
  };
  const handleBatchUpdateShow = () => {
    setshowBatchUpdate(true);
  };

  const handleEditUnits = async po => {

    setselectedSkid({ ...po });
    setshowUpdateCount(true);
    //  console.log(po);
    // let productsMap = {};
    // products.forEach(product => {
    //   let id = product.id;
    //   productsMap[id] = product.productName;
    // });
    // setproductNameMap({ ...productsMap });

    // let newSkid = {};
    // JSON.parse(po.original.productList).forEach(productId => {
    //   newSkid[productId] = 0;
    // });
    // let newSkidList = [];
    // newSkidList.push(newSkid);
    // setAddSkidList([...newSkidList]);
  };


  const handleBatchUpdateCompanyOnchange = e => {
    const value = e.target.value;
    if (value === "") {
      alert("Must select a Company to update");
    } else {
      const clone = skids.slice();
      const batchRecord = clone.filter(skid => skid.companyName === value);
      setcompanyName(value);
      setbatchStartRecord(batchRecord.slice());
      setbatchEndRecord(batchRecord.slice());
    }
  };
  const handleChangeStartDate = date => {
    setstartDate(date);
  };
  const handleChangeEndDate = date => {
    setendDate(date);
  };
  const handleBatchUpdateBarCodeStartOnBlur = () => {

    const index = batchStartRecord.findIndex(
      r => r.barCode === start
    );
    let batchEndRecord = batchStartRecord.slice(index + 1);
    setbatchEndRecord([...batchEndRecord]);
  };
  const handleBatchUpdateBarCodeStartOnFocus = () => {
    var clone = [...skids].slice();
    var batchStartRecord = clone.filter(
      skid => skid.companyName === companyName
    );
    setbatchStartRecord([...batchStartRecord])
    setend("");
  };
  // const handleBatchUpdateBarCodeEndOnchange = e => {
  //   const end = e.target.value;
  //   setend(end);
  // };
  const handleDownloadShow = () => {
    setshowDownload(true);
  };
  const handleDownloadClose = close => {
    setstartDate(undefined);
    setendDate(undefined);
    setshowDownload(false);
  };
  const handleBatchUpdate = async () => {
    let data;
    if (showBatchUpdate) {
      if (
        (user.role === "admin" || user.role === "superAdmin") && updateBasic
      ) {
        //update all information
        data = {
          start: start,
          end: end,
          height: height,
          lengths: length,
          width: width,
          weight: weight,
          action: "batch_update_basic_skid"
        };
      } else if (user.role === "admin" || user.role === "superAdmin") {
        //only update status
        data = {
          start: start,
          end: end,
          locatedWarehouseID: locatedWarehouseID,
          receivedDate: moment(receivedDate).format("YYYY-MM-DD"),
          action: "batch_update_receive_skid"
        };
      }
    } else if (showEditing) {
      if (
        (user.role === "admin" || user.role === "superAdmin") && selectedSkid.updateBasic
      ) {
        //update all information
        data = {
          start: selectedSkid.barCode,
          end: selectedSkid.barCode,
          height: selectedSkid.height,
          lengths: selectedSkid.length,
          width: selectedSkid.width,
          weight: selectedSkid.grossWeight,
          action: "batch_update_basic_skid"
        };
      } else if (user.role === "admin" || user.role === "superAdmin") {
        //only update status
        data = {
          start: selectedSkid.barCode,
          end: selectedSkid.barCode,
          locatedWarehouseID: selectedSkid.locatedWarehouseID,
          receivedDate: moment(selectedSkid.receivedDate).format("YYYY-MM-DD"),
          action: "batch_update_receive_skid"
        };
      }
    }
    for (let property in data) {
      if (data[property] === "") {
        // setshowBatchUpdate(false);
        openNotificationWithIcon("error", `Please Fill out all infomation`);
        return;
      }
    }
    await axios.post(
      `${BASE_URL}/skid/batch`,
      data,
      HEADER_OPTIONS_AUTH(user.token)
    )
      .then(function (response) {
        getSkids(user, dispatch);
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  };
  const handleSelectedSKIDOnchange = e => {
    let name = e.target ? e.target.name : "receivedDate";
    let value = (name === "updateBasic") ? e.target.checked : e.target ? e.target.value : e;
    let selectedSkidcopy = { ...selectedSkid };
    selectedSkidcopy[name] = value;
    setselectedSkid(selectedSkidcopy);
  };
  const handleStateOnchange = e => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;


    //   this.setState({
    //   [name]: value
    // });
  };

  const createSkid = async (user, newSKID) => {
    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      await axios.post(`${BASE_URL}/skid`, newSKID, options)
        .then(function (response) {

          dispatch({ type: CREATE_SKID, payload: response.data.data });

          localStorage.set("newSKID", newSKID);
          const newRows = [];
          for (let i = 0; i < newSKID.totalNumber; i++) {
            let num = (newSKID.startNumber + i).toString();
            let barCode = newSKID.taskNumber;
            if (num.length === 1) {
              barCode += "00" + num;
            } else if (num.length === 2) {
              barCode += "0" + num;
            } else {
              barCode += num;
            }
            const newRow = [barCode, newSKID.scheduledShipDate];
            newRows.push(newRow);
          }
          localStorage.set("newRows", newRows);
          // await this.props.uploadAveryDataFile(user, newRows);
          // this.setState({ newSKID: {} }, () => {
          //     window.location.reload();
          //     window.open(AVERY_URL, '_blank');
          // })
          // ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);
          var myWindow = window.open("/SkidLabelDocument");

        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
            dispatch({ type: CREATE_SKID, payload: [] });
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });


    }
    catch (e) {
      dispatch({ type: CREATE_SKID, payload: [] });
    }
  };

  const handleCreateSave = async () => {
    var newSKID = { ...newSKID };

    newSKID.scheduledShipDate = moment(newSKID.pickedDate).format("YYYY-MM-DD");
    if (
      newSKID.customerID === undefined ||
      newSKID.assignedWarehouseID === undefined
    ) {
      alert("Please fill missing informaiton");
    } else {
      await createSkid(user, newSKID);

    }
  };

  const handleSelectCompanyOnchange = e => {
    let value = e.target.value;
    if (value === "") {
      alert("Please choose the right CUSTOMER!");
      value = undefined;
    }
    var newSKID = { ...newSKID };
    newSKID.customerID = value;

    setnewSKID(newSKID);
  };
  const handleSelectWarehouseOnchange = e => {
    let value = e.target.value;
    if (value === "") {
      alert("Please choose the right CUSTOMER!");
      value = undefined;
    }
    var newSKID = { ...newSKID };
    newSKID.assignedWarehouseID = value;
    setnewSKID(newSKID);
  };
  const handleChangeDate = date => {
    var newSKID = { ...newSKID };
    newSKID.pickedDate = date;
    setnewSKID(newSKID);
  };
  const handleChangeReceivedDate = date => {
    setreceivedDate(date);
  };
  const NewSKIDOnchange = e => {
    const name = e.target.name;
    const value = e.target.value;
    const newSKID = { ...newSKID };
    newSKID[name] = value;
    setnewSKID(newSKID);
  };
  const handleTaskNumberBlur = async () => {

    const newSKID = { ...newSKID };
    const taskNumber = newSKID.taskNumber;
    const start = await getSKIDTaskStartNumber(user, taskNumber);
    newSKID.startNumber = start;
    setnewSKID(newSKID);
  };

  const handleDownloadReport = async () => {

    const options = HEADER_OPTIONS_AUTH(user.token);
    options.responseType = "blob";
    const data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    };
    try {
      await axios.post(
        `${BASE_URL}/download-processed-skid-report`,
        data,
        options
      )
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `skid_report.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    } catch (e) {
      alert("Download report failed!");
    }
  };

  const deleteSkid = async (skid) => {
    setdeleteLoading(true);
    //console.log(skid);
    await axios.patch(`${BASE_URL}/deleteSkid`, skid, HEADER_OPTIONS_AUTH(user.token))
      .then(async function (response) {
        setdeleteLoading(false);
        getSkids(user, dispatch);
        openNotificationWithIcon(
          "success",
          "Skid is marked as Deleted!"
        );
      })
      .catch(async function (error) {
        setdeleteLoading(false);
        console.log(error);
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }

  const restoreSkid = async (skid) => {
    setrestoreLoading(true);
    //console.log(skid);
    await axios.patch(`${BASE_URL}/restoreSkid`, skid, HEADER_OPTIONS_AUTH(user.token))
      .then(async function (response) {
        setrestoreLoading(false);
        getSkids(user, dispatch);
        openNotificationWithIcon(
          "success",
          "Skid is marked as Restored!"
        );
      })
      .catch(async function (error) {
        setrestoreLoading(false);
        console.log(error);
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }


  const columns = [
    {
      Header: "COMPANY NAME",
      accessor: "companyName",
      id: "companyNameInput",
      filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
    },
    {
      Header: "ORIGIN",
      accessor: "origin",
      width: 70,
      // accessor: d => d.friend.name // Custom value accessors!
      filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
    },
    {
      Header: "BAR CODE",
      accessor: "barCode",
      // accessor: d => d.friend.name // Custom value accessors!
      filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value.toUpperCase())
    },

    {
      // Header: props => <span>Friend Age</span>, // Custom header components!
      Header: "SCHEDULED SHIP DATE",
      id: "scheduledShipDate",
      accessor: row =>
        row.scheduledShipDate !== undefined &&
          row.scheduledShipDate !== "0000-00-00"
          ? `${row.scheduledShipDate.slice(0, 10)}`
          : "",
      filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    },
    {
      // Header: props => <span>Friend Age</span>, // Custom header components!
      Header: "RECEIVED DATE",
      id: "receivedDate",
      accessor: row =>
        row.receivedDate !== undefined && row.receivedDate !== "0000-00-00"
          ? `${row.receivedDate.slice(0, 10)}`
          : "NOT RECEIVE",
      filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    },
    // {
    //   Header: "GROSS WEIGHT",
    //   id: "grossWeight",
    //   accessor: row =>
    //     row.grossWeight === 0 || row.grossWeight === "0"
    //       ? "Need Update"
    //       : `${row.grossWeight}`,
    //   filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    // },
    // {
    //   Header: "LENGTH",
    //   id: "length",
    //   accessor: row =>
    //     row.length === 0 || row.length === "0"
    //       ? "Need Update"
    //       : `${row.length}`,
    //   filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    // },
    // {
    //   Header: "WIDTH",
    //   id: "width",
    //   accessor: row =>
    //     row.width === 0 || row.width === "0"
    //       ? "Need Update"
    //       : `${row.width}`,
    //   filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    // },
    // {
    //   Header: "HEIGHT",
    //   id: "height",
    //   accessor: row =>
    //     row.height === 0 || row.height === "0"
    //       ? "Need Update"
    //       : `${row.height}`,
    //   filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    // },
    {
      Header: "# UNIT",
      id: "units",
      width: 70,
      accessor: row =>
        row.units === 0 || row.units === "0"
          ? "Need Update"
          : `${row.units}`,
      filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
    },
    {
      Header: "LOCATED WAREHOUSE",
      id: "locatedWarehouseID",
      width: 80,
      accessor: row =>

        row.locatedWarehouseID === 0 || row.locatedWarehouseID === "0"
          ? "Need Update"
          : warehouses && warehouses.filter(warehouse => warehouse.id === row.locatedWarehouseID).map(warehouse => (warehouse.warehouseName)),

      filterMethod: (filter, row) => row[filter.id].toUpperCase().includes(filter.value)
    },
    {
      Header: "BILLING STATUS",
      accessor: "status",
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case "all":
            return true;
          case "UN_INVOICE":
            return row[filter.id] === "UN_INVOICE";
          case "READY_FOR_INVOICE":
            return row[filter.id] === "READY_FOR_INVOICE";
          case "INVOICED":
            return row[filter.id] === "INVOICED";
          default:
            return row[filter.id] === "Can't find status";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="UN_INVOICE">PENDING_RECEIPT</option>
          <option value="READY_FOR_INVOICE">READY_FOR_INVOICE</option>
          <option value="INVOICED">INVOICED</option>
        </select>
      )
    },
    {
      Header: "PROCESSING STATUS",
      accessor: "process_status",
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case "all":
            return true;
          case "UN_RECEIVE":
            return row[filter.id] === "UN_RECEIVE";
          case "PROCESSED":
            return row[filter.id] === "PROCESSED";
          default:
            return row[filter.id] === "Can't find status";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="UN_RECEIVE">NOT_RECEIVED</option>
          <option value="PROCESSED">PROCESSED</option>
        </select>
      )
    },
    {
      Header: "CLOSE STATUS",
      accessor: "close_status",
      filterMethod: (filter, row) => {
        switch (filter.value) {
          case "all":
            return true;
          case "Closed":
            return row[filter.id] === "Closed";
          case "Open":
            return row[filter.id] === "Open";
          case "ClosedAndInvoiced":
            return row[filter.id] === "ClosedAndInvoiced";
          default:
            return row[filter.id] === "Can't find status";
        }
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "all"}
        >
          <option value="all">Show All</option>
          <option value="Closed">Closed</option>
          <option value="Open">Open</option>
          <option value="ClosedAndInvoiced">ClosedAndInvoiced</option>
        </select>
      )
    },
    {
      Header: "EDIT",
      sortable: false,
      filterable: false,
      width: 135,
      Cell: pro => (
        <div>
          <Button
            variant="primary"
            size="sm block"
            onClick={() => handleEditShow(pro.original)}
          >
            Edit
            </Button>
          {pro.original.close_status !== "ClosedAndInvoiced" ?
            <Button
              variant="primary"
              size="sm block"
              style={{ marginLeft: '5px' }}
              onClick={() => handleEditUnits(pro.original)}
            >
              Edit Units
           </Button> : <div></div>}
        </div>
      )
    },
    {
      Header: "DELETE",
      sortable: false,
      filterable: false,
      width: 80,
      Cell: pro => (
        <div>
          {pro.original.close_status === "Open" && pro.original.process_status === "UN_RECEIVE" && pro.original.deleted === 0 ?
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteSkid(pro.original)}>
              <Button
                variant="danger"
                size="sm"
                loading={deleteLoading}
              >
                DELETE
           </Button>
            </Popconfirm>
            : pro.original.deleted === 1 ?
              <Popconfirm title="Sure to restore?" onConfirm={() => restoreSkid(pro.original)}>
                <Button
                  variant="danger"
                  size="sm"
                  loading={restoreLoading}
                >
                  RESTORE
           </Button>
              </Popconfirm> : <div></div>}
        </div>
      )
    }
  ];

  const updateSkidTableData = async () => {
    setshowUpdateCount(false);
    getSkids(user, dispatch);
  }

  const UpdateModal = () => {
    const [updatedSkid, setupdatedSkid] = useState(selectedSkid);
    const [loading, setLoading] = useState(false);

    const refreshData = async () => {
      updateSkidTableData();
    }

    const updateSkidCount = async () => {
      setLoading(true);
      try {
        const options = HEADER_OPTIONS_AUTH(user.token);

        await axios.post(
          `${BASE_URL}/skid/updateCount`,
          updatedSkid,
          options
        )
          .then(function (response) {
            setLoading(false);
            refreshData();
          })
          .catch(function (error) {
            setLoading(false);
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon(
                "error",
                error.response.data.message
              );
            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
          });

      } catch (e) {
        if (e.message.includes('409')) {
          alert("Please enter a unique PO#. Duplicate PO#'s are not allowed while creating new orders");
        } else {
          alert(e.message);
        }
        setLoading(false);
      }
    }

    const updateSkidQuantity = (key, e, index) => {
      let skidContent = JSON.parse(updatedSkid.skidContent)
      skidContent[key] = e;
      let newUpdatedSkid = { ...selectedSkid };
      newUpdatedSkid.skidContent = JSON.stringify(skidContent);
      setupdatedSkid(newUpdatedSkid);
    }

    return (
      updatedSkid !== undefined && (
        <Modal
          backdrop="static"
          show={showUpdateCount}
          onHide={() => handleClose("showUpdateCount")}
          centered
          dialogClassName={"update-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Skid Count</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <AntdForm
              layout="horizontal"
              onFinish={(val) => { if (window.confirm("Do you confirm to update the existing skid?")) { updateSkidCount() } }}
              style={{ margin: "0", padding: "0" }}
            >
              <Card
                style={{
                  marginBottom: "5px"
                }}
                bodyStyle={{ padding: '0px' }}
              >
                <Row style={{ backgroundColor: "#2b579a", marginLeft: '0px', marginRight: '0px' }}>
                  <Col span={4}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                          Piece{" "}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                          Weight (lbs)
                        </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                          Length (inches)
                        </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "14px"
                      }}
                    >
                      Width (inches)
                        </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                          Height (inches)
                        </div>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "35px", marginRight: "5px", marginLeft: '0px', marginRight: '0px' }}>
                  <Col span={4}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                      {1}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                      {skidDimensions.grossWeight}{" "}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                      {skidDimensions["length"]}{" "}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px"
                      }}
                    >
                      {" "} {skidDimensions["width"]} {" "}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{
                        textAlign: "center",
                        color: "black",
                        fontSize: "14px"
                      }}
                    >
                      {" "}
                      {skidDimensions["height"]}{" "}
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px', padding: '0px' }}>
                  <Col
                    span={24}
                    style={{
                      marginLeft: "15px",
                      margintRight: "15px",
                      marginBottom: "15px"
                    }}
                  >
                    {Object.keys(JSON.parse(selectedSkid.skidContent)).map((key, index) => (
                      <Row key={index} >
                        <Col span={17} align="right">
                          <h6>
                            {productNameMap[key.toString()]}
                          </h6>
                        </Col>
                        <Col span={7} align="left">
                          <AntdForm.Item
                            label=""
                            name={`${index}${key}ProductQuantity`}
                            initialValue={JSON.parse(selectedSkid.skidContent)[key]}
                            rules={[
                              {
                                required: true,
                                message: "Please input Quantity!"
                              }
                            ]}
                            style={{ marginRight: "5px" }}
                          >
                            <InputNumber
                              min={0}
                              value={JSON.parse(selectedSkid.skidContent)[key]}
                              onChange={e => {
                                updateSkidQuantity(key, e, index);
                              }}
                            />
                          </AntdForm.Item>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Card>
              {/* ))} */}

              <AntdButton
                variant="primary"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ float: "right", marginLeft: "12px" }}
              >
                Update
                </AntdButton>
              <AntdButton
                variant="secondary"
                style={{ float: "right" }}
                onClick={() => handleClose("showUpdateCount")}
              >
                Close
                </AntdButton>
            </AntdForm>
          </Modal.Body>
        </Modal>)
    )
  }

  return (
    <div>
      <UpdateModal />
      {selectedSkid !== undefined && (
        <Modal
          backdrop="static"
          show={showEditing}
          onHide={() => handleClose("showEditing")}
          centered
          dialogClassName={"user-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Updating SKID</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive bordered={true} style={{ marginBottom: "0px" }}>
              <tbody>
                <tr>
                  <th>Company Name</th>
                  <td>{selectedSkid.companyName}</td>
                </tr>
                <tr>
                  <th>Origin</th>
                  <td>
                    {selectedSkid.origin}
                    {/* <Form.Control
                      type="text"
                      value={selectedSkid.origin}
                      name={"origin"}
                      onChange={handleSelectedSKIDOnchange}
                    /> */}
                  </td>
                </tr>
                <tr>
                  <th>Bar Code</th>
                  <td>{selectedSkid.barCode}</td>
                </tr>
                <tr>
                  <th>Update Basic Information?</th>
                  <td>
                    <Form.Control
                      type="checkbox"
                      name={"updateBasic"}
                      onChange={handleSelectedSKIDOnchange}
                      checked={selectedSkid.updateBasic}
                    />
                  </td>
                </tr>
                {(user.role === "admin" || user.role === "superAdmin") && selectedSkid.updateBasic && (
                  <tr>
                    <th>Gross Weight (kg)</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={selectedSkid.grossWeight}
                        name={"grossWeight"}
                        onChange={handleSelectedSKIDOnchange}
                      />
                    </td>
                  </tr>
                )}
                {(user.role === "admin" || user.role === "superAdmin") && selectedSkid.updateBasic && (
                  <tr>
                    <th>Length (inch)</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={selectedSkid.length}
                        name={"length"}
                        onChange={handleSelectedSKIDOnchange}
                      />
                    </td>
                  </tr>
                )}
                {(user.role === "admin" || user.role === "superAdmin") && selectedSkid.updateBasic && (
                  <tr>
                    <th>Width (inch)</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={selectedSkid.width}
                        name={"width"}
                        onChange={handleSelectedSKIDOnchange}
                      />
                    </td>
                  </tr>
                )}
                {(user.role === "admin" || user.role === "superAdmin") && selectedSkid.updateBasic && (
                  <tr>
                    <th>Height (inch)</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={selectedSkid.height}
                        name={"height"}
                        onChange={handleSelectedSKIDOnchange}
                      />
                    </td>
                  </tr>
                )}
                {/* <tr>
                    <th># Unit</th>
                    <td>
                      <Form.Control
                        type="text"
                        value={selectedSkid.units}
                        name={"units"}
                        onChange={this.handleSelectedSKIDOnchange}
                      />
                    </td>
                  </tr> */}
                {(user.role === "admin" || user.role === "superAdmin") && !selectedSkid.updateBasic && (
                  <tr>
                    <th>Received Date</th>
                    <td>
                      <DatePicker
                        selected={
                          selectedSkid.receivedDate !== "0000-00-00" ? new Date(selectedSkid.receivedDate) : null
                        }
                        onChange={handleSelectedSKIDOnchange}
                        disabled={selectedSkid.process_status !== "UN_RECEIVE"}
                      />
                    </td>
                  </tr>
                )}
                {(user.role === "admin" || user.role === "superAdmin") && !selectedSkid.updateBasic && (
                  <tr>
                    <th>Located Warehouse</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={handleSelectedSKIDOnchange}
                        name={"locatedWarehouseID"}
                        value={selectedSkid.locatedWarehouseID}
                      >

                        <option value={""}>---Select---</option>
                        {warehouses && warehouses.map((warehouse, index) => {
                          return (
                            <option key={index} value={warehouse.id} >
                              {warehouse.warehouseName}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </td>
                  </tr>
                )}
                {/* {(user.role === "admin" || user.role === "superAdmin") && (
                  <tr>
                    <th>Billing Status</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={handleSelectedSKIDOnchange}
                        name={"status"}
                        default={selectedSkid.status}
                      >
                        <option value={selectedSkid.status}>
                          ---SELECT---
                          </option>
                        <option value={"UN_INVOICE"}>PENDING_RECEIPT</option>
                        <option value={"READY_FOR_INVOICE"}>
                          READY_FOR_INVOICE
                          </option>
                        <option value={"INVOICED"}>INVOICED</option>
                      </Form.Control>
                    </td>
                  </tr>
                )}
                {user.role === "admin" || user.role === "superAdmin" && (
                  <tr>
                    <th>Processing Status</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={handleSelectedSKIDOnchange}
                        name={"process_status"}
                        default={selectedSkid.process_status}
                      >
                        <option value={selectedSkid.process_status}>
                          ---SELECT---
                          </option>
                        <option value={"UN_RECEIVE"}>NOT_RECEIVED</option>
                        <option value={"PROCESSED"}>PROCESSED</option>
                      </Form.Control>
                    </td>
                  </tr>
                )} */}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleClose("showEditing")}
            >
              Close
              </Button>
            <Button variant="primary" onClick={handleBatchUpdate}>
              {selectedSkid.updateBasic ? "Update Basic" : "Update Receive"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Modal
        backdrop="static"
        show={showCreate}
        onHide={() => handleClose("showCreate")}
        centered
        dialogClassName={"user-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New SKID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered={true} style={{ marginBottom: "0px" }}>
            <tbody>
              <tr>
                <th>Company/Customer</th>
                <td>
                  <Form.Control
                    as="select"
                    onChange={handleSelectCompanyOnchange}
                  >
                    <option value={""}>---Select---</option>
                    {users.map((user, index) => {
                      return (
                        <option key={index} value={user.id}>
                          {user.companyName} - {user.firstName}{" "}{user.lastName}
                        </option>
                      );
                    })}
                  </Form.Control>
                </td>
              </tr>
              {warehouses && (
                <tr>
                  <th>Warehouse</th>
                  <td>
                    <Form.Control
                      as="select"
                      onChange={handleSelectWarehouseOnchange}
                    >
                      <option value={""}>---Select---</option>
                      {warehouses.map((warehouse, index) => {
                        return (
                          <option key={index} value={warehouse.id}>
                            {warehouse.warehouseName} - {warehouse.city}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </td>
                </tr>
              )}

              <tr>
                <th>Origin</th>
                <td>
                  <td>
                    <Form.Control
                      type="text"
                      value={
                        newSKID.origin === undefined
                          ? ""
                          : newSKID.origin
                      }
                      name={"origin"}
                      onChange={NewSKIDOnchange}
                    />
                  </td>
                </td>
              </tr>
              <tr>
                <th>Scheduled Ship Date</th>
                <td>
                  <DatePicker
                    selected={
                      newSKID.pickedDate === undefined
                        ? null
                        : newSKID.pickedDate
                    }
                    onChange={handleChangeDate}
                  />
                </td>
              </tr>
              <tr>
                <th>Batch Number</th>
                <td>
                  <Form.Control
                    type="text"
                    value={
                      newSKID.taskNumber === undefined
                        ? ""
                        : newSKID.taskNumber
                    }
                    name={"taskNumber"}
                    onChange={NewSKIDOnchange}
                    onBlur={handleTaskNumberBlur}
                  />
                </td>
              </tr>
              <tr>
                <th>Start Number</th>
                <td>
                  <Form.Control
                    type="text"
                    value={
                      newSKID.startNumber === undefined
                        ? ""
                        : newSKID.startNumber
                    }
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th>Total Number</th>
                <td>
                  <Form.Control
                    type="text"
                    value={
                      newSKID.totalNumber === undefined
                        ? ""
                        : newSKID.totalNumber
                    }
                    name={"totalNumber"}
                    onChange={NewSKIDOnchange}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleClose("showCreate")}
          >
            Close
            </Button>
          <Button variant="primary" onClick={handleCreateSave}>
            Save Changes
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        backdrop="static"
        show={showDownload}
        onHide={() => handleDownloadClose("showDownload")}
        centered
        dialogClassName={"user-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Skid Record Download</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered={true} style={{ marginBottom: "0px" }}>
            <tbody>
              <tr>
                <th>Start Date</th>
                <td>
                  <DatePicker
                    selected={
                      startDate === undefined
                        ? null
                        : startDate
                    }
                    onChange={handleChangeStartDate}
                  />
                </td>
              </tr>
              <tr>
                <th>End Date</th>
                <td>
                  <DatePicker
                    selected={
                      endDate === undefined
                        ? null
                        : endDate
                    }
                    onChange={handleChangeEndDate}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleDownloadClose("showDownload")}
          >
            Close
            </Button>
          <Button variant="primary" onClick={handleDownloadReport}>
            Download Report
            </Button>
        </Modal.Footer>
      </Modal>

      {skids.length !== undefined && (
        <Modal
          backdrop="static"
          show={showInvoiceForm}
          onHide={() => handleClose("showInvoiceForm")}
          centered
          dialogClassName={"user-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invoice id</Modal.Title>
          </Modal.Header>
          <Modal.Body />
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleClose("showInvoiceForm")}
            >
              Close
              </Button>
            <Button variant="primary" onClick={handleBatchUpdate}>
              Submit
              </Button>
          </Modal.Footer>
        </Modal>
      )}

      {skids.length !== undefined && (
        <Modal
          backdrop="static"
          show={showBatchUpdate}
          onHide={() => handleClose("showBatchUpdate")}
          centered
          dialogClassName={"user-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Batch Update SKID(Please Fill out all informaiton)
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive bordered={true} style={{ marginBottom: "0px" }}>
              <tbody>
                <tr>
                  <th>Company Name</th>
                  <td>
                    <Form.Control
                      as="select"
                      onChange={handleBatchUpdateCompanyOnchange}
                    >
                      <option value={""}>---Select---</option>
                      {companyNames.map((company, index) => {
                        return (
                          <option key={index} value={company}>
                            {company}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <th>Bar Code Start</th>
                  <td>
                    <Form.Control
                      as="select"
                      name={"start"}
                      onChange={(e) => setstart(e.target.value)}
                      // onBlur={handleBatchUpdateBarCodeStartOnBlur}
                      // onFocus={handleBatchUpdateBarCodeStartOnFocus}
                      default={start}
                    >
                      <option value={""}>---Select---</option>
                      {batchStartRecord.map((row, index) => {
                        return (
                          <option key={index} value={row.barCode}>
                            {row.barCode}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </td>
                </tr>
                <tr>
                  <th>Bar Code End</th>
                  <td>
                    <Form.Control
                      as="select"
                      name={"end"}
                      onChange={(e) => setend(e.target.value)}
                      default={end}
                    >
                      <option value={""}>---Select---</option>
                      {batchEndRecord.map((row, index) => {
                        return (
                          <option key={index} value={row.barCode}>
                            {row.barCode}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </td>
                </tr>

                {(user.role === "admin" || user.role === "superAdmin") && (
                  <tr>
                    <th>Update Basic Information?</th>
                    <td>
                      <Form.Control
                        type="checkbox"
                        name={"updateBasic"}
                        onChange={(e) => setupdateBasic(e.target.checked)}
                        checked={updateBasic}
                      />
                    </td>
                  </tr>
                )}
                {(((user.role === "admin" || user.role === "superAdmin") &&
                  updateBasic) ||
                  user.role === "client") && (
                    <tr>
                      <th>Gross Weight (kg)</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={weight}
                          name={"weight"}
                          onChange={(e) => setweight(e.target.value)}
                        />
                      </td>
                    </tr>
                  )}
                {(((user.role === "admin" || user.role === "superAdmin") &&
                  updateBasic) ||
                  user.role === "client") && (
                    <tr>
                      <th>Length (inch)</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={length}
                          name={"length"}
                          onChange={(e) => setlength(e.target.value)}
                        />
                      </td>
                    </tr>
                  )}
                {(((user.role === "admin" || user.role === "superAdmin") &&
                  updateBasic) ||
                  user.role === "client") && (
                    <tr>
                      <th>Width (inch)</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={width}
                          name={"width"}
                          onChange={(e) => setwidth(e.target.value)}
                        />
                      </td>
                    </tr>
                  )}
                {(((user.role === "admin" || user.role === "superAdmin") &&
                  updateBasic) ||
                  user.role === "client") && (
                    <tr>
                      <th>Height (inch)</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={height}
                          name={"height"}
                          onChange={(e) => setheight(e.target.value)}
                        />
                      </td>
                    </tr>
                  )}
                {/* {(((user.role === "admin" || user.role === "superAdmin" || user.role === "operator") &&
                  updateBasic) ||
                  user.role === "client") && (
                    <tr>
                      <th># Unit</th>
                      <td>
                        <Form.Control
                          type="text"
                          value={units}
                          name={"units"}
                          onChange={(e) => setunits(e.target.value)}
                        />
                      </td>
                    </tr>
                  )} */}
                {(user.role === "admin" || user.role === "superAdmin") && !updateBasic && (
                  <tr>
                    <th>Received Date</th>
                    <td>
                      <DatePicker
                        selected={
                          receivedDate === undefined
                            ? null
                            : receivedDate
                        }
                        onChange={(e) => setreceivedDate(e)}
                      />
                    </td>
                  </tr>
                )}
                {(user.role === "admin" || user.role === "superAdmin") && !updateBasic && (
                  <tr>
                    <th>Located Warehouse</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={(e) => setlocatedWarehouseID(e.target.value)}
                        name={"locatedWarehouseID"}
                        default={locatedWarehouseID}
                      >
                        <option value={""}>---Select---</option>
                        {warehouses && warehouses.map((warehouse, index) => {
                          return (
                            <option key={index} value={warehouse.id}>
                              {warehouse.warehouseName} - {warehouse.city}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </td>
                  </tr>
                )}
                {/* {(user.role === "admin" || user.role === "superAdmin" || user.role === "operator") && !updateBasic && (
                  <tr>
                    <th>Status</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={(e) => setstatus(e.target.value)}
                        name={"status"}
                        default={status}
                      >
                        <option value={status}>
                          ---SELECT---
                          </option>
                        <option value={"UN_INVOICE"}>PENDING_RECEIPT</option>
                        <option value={"READY_FOR_INVOICE"}>
                          READY_FOR_INVOICE
                          </option>
                        <option value={"INVOICED"}>INVOICED</option>
                      </Form.Control>
                    </td>
                  </tr>
                )}
                {user.role === "admin" || user.role === "superAdmin" && !updateBasic && (
                  <tr>
                    <th>Processing Status</th>
                    <td>
                      <Form.Control
                        as="select"
                        onChange={(e) => setprocess_status(e.target.value)}
                        name={"process_status"}
                        default={process_status}
                      >
                        <option value={process_status}>
                          ---SELECT---
                          </option>
                        <option value={"UN_RECEIVE"}>NOT_RECEIVED</option>
                        <option value={"PROCESSED"}>PROCESSED</option>
                      </Form.Control>
                    </td>
                  </tr>
                )} */}

              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => handleClose("showBatchUpdate")}
            >
              Close
              </Button>
            <Button variant="primary" onClick={handleBatchUpdate}>
              {updateBasic ? "Batch Update Basic" : "Batch Update Receive"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <AntdRow style={{ textAlign: "center", alignContent: "center", paddingTop: "15px", paddingBottom: "15px" }}>
        <AntdCol span={18} >
          <h1>Manage SKIDs</h1>
        </AntdCol>
        <AntdCol span={3} style={{ marginTop: "10px" }}>
          <Button variant="success" onClick={handleDownloadShow}>
            Download Report
              </Button>
        </AntdCol>
        <AntdCol span={3} style={{ marginTop: "10px" }}>
          <Button variant="primary" onClick={handleBatchUpdateShow}>
            Batch Update
              </Button>
        </AntdCol>
      </AntdRow>

      {skids.length !== undefined && (
        <ReactTable
          style={{ marginBottom: "20px" }}
          data={skids}
          filterable
          minRows={1}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={columns}
        />
      )}

    </div>
  );

}

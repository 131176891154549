import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Input,
    Switch,
    Row,
    Col
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';
import { getClients } from '../../actions/index';

export const CreateClient = (params) => {


    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "businessRep" && user.role !== "admin")) {
            history.push('/');
        }
    }, [])

    const handleCreateClient = async (values) => {

        setLoading(true);
        const data = {
            qbClientInfo: {
                FullyQualifiedName: values.FullyQualifiedName ? values.FullyQualifiedName : "",
                PrimaryEmailAddr: {
                    Address: values.Address ? values.Address : ""
                },
                DisplayName: values.DisplayName ? values.DisplayName : "",
                Suffix: values.Suffix ? values.Suffix : "",
                Title: values.Title ? values.Title : "",
                MiddleName: values.MiddleName ? values.MiddleName : "",
                Notes: values.Notes ? values.Notes : "",
                FamilyName: values.FamilyName ? values.FamilyName : "",
                PrimaryPhone: {
                    FreeFormNumber: values.FreeFormNumber ? values.FreeFormNumber : ""
                },
                CompanyName: values.CompanyName ? values.CompanyName : "",
                BillAddr: {
                    CountrySubDivisionCode: values.CountrySubDivisionCode ? values.CountrySubDivisionCode : "",
                    City: values.City ? values.City : "",
                    PostalCode: values.PostalCode ? values.PostalCode : "",
                    Line1: values.Line1 ? values.Line1 : "",
                    Country: values.Country ? values.Country : ""
                },
                GivenName: values.GivenName ? values.GivenName : ""
            },
            shortCut: values.shortCut,
            composite: "",
            exchangerate: 1,
            postofficebox: "",
            primarycontactname: values.primarycontactname ? values.primarycontactname : "",
            stateorprovince: values.stateorprovince ? values.stateorprovince : "",
            summarizedInvoice: values.summarizedInvoice ? values.summarizedInvoice : 0
        };

        await axios.post(`${BASE_URL}/createClient`, data, HEADER_OPTIONS_AUTH(user.token))
            .then(function (response) {
                setLoading(false);
                openNotificationWithIcon('success', 'Create Client');
                getClients(user, dispatch);
                form.resetFields();
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    setLoading(false);
                    openNotificationWithIcon('error', `Create Client due to ${error.response.data.message}`);

                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
            });

    };



    const layout = {
        labelCol: { span: 14 },
        wrapperCol: { span: 10 },
    };



    return (
        <div style={{ marginLeft: "10px", marginRight: "50px", marginTop: "30px", marginBottom: "40px", textAlign: 'center' }}>

            <Form
                {...layout}
                size="medium"
                className="createclient-form"
                onFinish={handleCreateClient}
                form={form}
            >
                <Row>
                    <Col span={11}>
                        <Form.Item
                            label="Company Fully Qualified Name:"
                            name="FullyQualifiedName"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Company Name:"
                            name="CompanyName"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Company Name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Company Display Name:"
                            name="DisplayName"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Display Name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Primary Contact Person Suffix:"
                            name="Suffix"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Primary Contact Person Title:"
                            name="Title"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Primary Contact Person First Name:"
                            name="GivenName"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input First Name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Primary Contact Person Middle Name:"
                            name="MiddleName"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Primary Contact Person Last Name:"
                            name="FamilyName"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Last Name!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="Primary Phone:"
                            name="FreeFormNumber"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Primary Phone!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Primary Contact Email Address:"
                            name="Address"

                            rules={[
                                {
                                    type: "email",
                                    required: true,
                                    message: "The input is not valid E-mail!"
                                }
                            ]}
                        >

                            <Input />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Short Cut (e.g. HOND, MERC, GM, etc) :"
                            name="shortCut"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Short Cut!"
                                }, {

                                    pattern: new RegExp(
                                        /^[A-Z]+$/i
                                    ),
                                    message: "Please input only Capital Letters!"
                                }
                            ]}
                        >
                            <Input maxLength={4} />
                        </Form.Item>

                        <Form.Item
                            label="Address Line 1:"
                            name="Line1"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Address!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="City:"
                            name="City"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input City!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="State or Province:"
                            name="stateorprovince"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Province!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Postal Code:"
                            name="PostalCode"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Postal Code!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Country:"
                            name="Country"

                            rules={[
                                {
                                    required: true,
                                    message: "Please input Country!"
                                }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Country Sub Division Code:"
                            name="CountrySubDivisionCode"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Primary Contact Name:"
                            name="primarycontactname"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Notes:"
                            name="Notes"

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Summarized Invoice:"
                            name="summarizedInvoice"

                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    wrapperCol={{
                        span: 5,
                        offset: 10
                    }}
                    style={{ marginTop: "40px" }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginLeft: "15px" }}
                        size="large"
                        loading={loading}
                        hidden={user.role === "businessRep" || user.role === "admin"}
                    >
                        Create Client
       </Button>
                </Form.Item>

            </Form>
        </div>
    )

}
import { InboxOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Checkbox, Col, Divider, List, Popconfirm, Row, Spin, Statistic, Tag } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProcessingInvoiceSkids, getProducts } from '../../../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';


export const ProcessingInvoiceUI = (params) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const processingInvoiceSkids = useSelector(state => state.processingInvoiceSkids);
  const totalClosedSkids = useSelector(state => state.processingInvoiceSkids).totalClosedSkids;
  const skidCountForInvoice = useSelector(state => state.processingInvoiceSkids).skidCountForInvoice;
  const [closedSkids, setclosedSkids] = useState(undefined);
  const products = useSelector(state => state.products);
  const [productsMap, setproductsMap] = useState({});
  const [skidsForInvoice, setSkidsForInvoice] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user || (user.role !== "superAdmin" && user.role !== "admin")) {
      history.push('/');
    } else {
      getProducts(user, dispatch);
      getProcessingInvoiceSkids(user, dispatch);
    }
  }, [])

  useEffect(() => {
    if (products) {
      let productMap = {};
      products.forEach(product => {
        let id = product.id;
        productMap[id] = product.productName;
      });
      setproductsMap({ ...productMap });
    }
  }, [products]);

  useEffect(() => {
    if (processingInvoiceSkids.rows && processingInvoiceSkids.rows.length > 0) {
      let formattedData = {};
      // console.log(processingInvoiceSkids.rows);
      processingInvoiceSkids.rows.map(skid => {
        if (!formattedData.hasOwnProperty(skid.companyName)) {
          formattedData[skid.companyName] = [];
        }
        if (typeof skid.skidContent !== 'object') {
          skid.skidContent = JSON.parse(skid.skidContent);
        }
        if (typeof skid.skidProcessed !== 'object') {
          skid.skidProcessed = JSON.parse(skid.skidProcessed);
        }
        formattedData[skid.companyName].push(skid);
      })
      setclosedSkids(formattedData);
    }
  }, [processingInvoiceSkids])

  const handleSelectSkid = (value, item, closedSkidCompanyName, j) => {

    var changeChecked = true;
    if (value.target.checked) {
      if (skidsForInvoice.length > 0) {
        if (skidsForInvoice[0].orderNumber === item.orderNumber) {
          if (skidsForInvoice.length === 50) {
            changeChecked = false;
            openNotificationWithIcon('warning', `You can only select 50 skids at a time.`);
          } else {
            setSkidsForInvoice(skidsForInvoice => skidsForInvoice.concat(item));
          }
        } else {
          changeChecked = false;
          openNotificationWithIcon('warning', `You cannot select skids from different purchase order`);
        }
      } else {
        setSkidsForInvoice((skidsForInvoice) => skidsForInvoice.concat(item));
      }
    } else {
      setSkidsForInvoice((skidsForInvoice) => skidsForInvoice.filter(skid => skid.skidId !== item.skidId));
    }
    if (changeChecked) {
      var newClosedSkids = { ...closedSkids };
      newClosedSkids[closedSkidCompanyName][j].selected = !newClosedSkids[closedSkidCompanyName][j].selected;
      //  closedSkids=({...newClosedSkids});
    }

  }

  const calculateBuffer = (item, currentItemMap) => {

    let skidContent = { ...item.skidContent };
    for (let p in skidContent) {
      if (currentItemMap[p]) {
        skidContent[p] = skidContent[p] - currentItemMap[p] < 0 ? 0 : skidContent[p] - currentItemMap[p]
      }
    }
    return skidContent

  }

  const handleCreateInvoiceBySkid = async (item) => {

    const options = HEADER_OPTIONS_AUTH(user.token);
    setLoading(true);
    await axios.post(`${BASE_URL}/createQBProcessingInvoiceWithSkidIds`, [item], options)
      .then(function (response) {
        setLoading(false);
        getProcessingInvoiceSkids(user, dispatch);
        openNotificationWithIcon('success', `Invoice created successfully.`);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  const handleCreateBigInvoice = async () => {
    // console.log(skidsForInvoice);
    if (skidsForInvoice.length > 0) {

      const options = HEADER_OPTIONS_AUTH(user.token);
      setLoading(true);
      await axios.post(`${BASE_URL}/createQBProcessingInvoiceWithSkidIds`, skidsForInvoice, options)
        .then(function (response) {
          setLoading(false);
          getProcessingInvoiceSkids(user, dispatch);
          openNotificationWithIcon('success', `Invoice created successfully.`);
        })
        .catch(function (error) {
          setLoading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });
    } else {
      openNotificationWithIcon('warning', `Please select skids to create purchase order`);
    }
  }

  return (
    <div>
      <h1 align="center">Skid Status Invoicing</h1>

      <Row gutter={16}>
        <Col span={12}>
          <Tag color="red">Total Closed Sku: </Tag>
          <Statistic value={skidCountForInvoice} suffix={`/ ${totalClosedSkids}`} />
        </Col>
      </Row>
      {closedSkids && Object.keys(closedSkids).map((closedSkidCompanyName, i) => (

        <div key={i}>
          <h5 style={{ fontWeight: "bold" }}><Divider><Spin />{closedSkidCompanyName}</Divider></h5>
          <List
            itemLayout="horizontal"
            dataSource={closedSkids[closedSkidCompanyName]}
            renderItem={(item, j) => {

              //      let bufferedItemsNum = calculateBufferitems(item, item.totalProcessed)
              let calculatedBuffer = calculateBuffer(item, item.skidProcessed)

              return (
                <List.Item
                  key={j}
                  // action pannel
                  actions={[
                    <div>
                      {Object.keys(item.skidContent).map((productId) => {
                        return (
                          <div key={productId.concat(item.barCode)}>
                            <span style={{ fontWeight: "bolder" }}>{productsMap[productId] ? productsMap[productId] : ""}: </span>
                            <span>
                              {item.skidProcessed[productId] ? item.skidProcessed[productId] : 0} <span style={{ color: "green" }}>(+ {calculatedBuffer[productId]})</span> / {item.skidContent[productId]}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    ,
                    <Popconfirm
                      title={`Do you confirm to create invoice with ${item.units - item.totalProcessed} buffer items?`}
                      onConfirm={() => {
                        handleCreateInvoiceBySkid(item)
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        size={"large"} loading={loading} hidden={user.role === "businessRep" || user.role === "admin"} style={{ color: "aliceblue", backgroundColor: "#52c41a" }}>Create Invoice</Button>
                    </Popconfirm>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Badge count={item.totalProcessed} overflowCount={999}>
                        <Checkbox onChange={(value) => { handleSelectSkid(value, item, closedSkidCompanyName, j) }} checked={item.selected} style={{ marginRight: 10 }}></Checkbox>
                        <Avatar shape="square" size="large" style={{ backgroundColor: '#f56a00' }} icon={<InboxOutlined />} />
                      </Badge>
                    }
                    title={<div><Tag color="blue">{item.barCode}</Tag><Tag color="#87d068">{item.close_status === "Closed" ? "Closed" : "Not Closed"}</Tag><Tag color="#398eff">{item.summarizedInvoice === 1 ? "Summarized" : "Detailed"}</Tag></div>}
                    description={<p style={{ color: "black", fontWeight: "bold" }}>{`From PO:${item.orderNumber} received on ${item.receivedDate.slice(0, 10)}`}</p>}
                  />
                </List.Item>
              )


            }}
          />
          <Divider />
          <Row type="flex" justify="end">
            <Col>
              {user.role === "businessRep" || user.role === "admin" ? <div></div> :
                (<div>
                  <span>Create one big invoice for selected skids: </span>
                  <Popconfirm
                    title={`Do you confirm to create invoice for selected skids?`}
                    onConfirm={() => {
                      handleCreateBigInvoice(closedSkidCompanyName)
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size={"large"} hidden={user.role === "businessRep" || user.role === "admin"} style={{ color: "black", backgroundColor: "red", borderColor: "white" }} loading={loading} type={"primary"}>
                      Create Invoice
            </Button>
                  </Popconfirm>
                </div>
                )
              }
            </Col>
          </Row>
        </div>
      ))}
    </div>
  )
}

import { Button, Card, Col, Row } from 'antd';
import localStorage from "local-storage";
import React, { Component } from 'react';
import Barcode from 'react-barcode';
import { connect } from "react-redux";
import ReactToPrint from 'react-to-print';
import * as actions from "../../actions";
import labelLogo from "./img/labelLogo.png";



class AllLabels extends React.Component {

    componentWillMount() {

    }

    render() {
        let newSkid = localStorage.get("newSKID")
        let newRows = localStorage.get("newRows")

        console.log()
        return (
            <div >
                {newRows.map((row) => {
                    return (
                        <div style={{ height: "100%" }}>
                            <Row type="flex" justify="space-around">
                                <Col>
                                    <Card
                                        style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.7in", height: "6in" }}
                                        cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                    >
                                        <Row>
                                            <Col>
                                                BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{row[1]}</span>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                GROSS WEIGHT:________ KG's
                                    </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Barcode value={row[0]} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                SHIP TO LOCATION:
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[2]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[3]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}{row[4]}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.7in", height: "6in" }}
                                        cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                    >
                                        <Row>
                                            <Col>
                                                BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{row[1]}</span>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                GROSS WEIGHT:________ KG's
                                    </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Barcode value={row[0]} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                SHIP TO LOCATION:
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[2]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[3]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}{row[4]}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row type="flex" justify="space-around">
                                <Col>
                                    <Card
                                        style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.7in", height: "6in" }}
                                        cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                    >
                                        <Row>
                                            <Col>
                                                BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{row[1]}</span>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                GROSS WEIGHT:________ KG's
                                    </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Barcode value={row[0]} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                SHIP TO LOCATION:
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[2]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[3]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}{row[4]}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        style={{ margin: "0.5in 0.5in 0.5in 0.5in", textAlign: "center", width: "4.7in", height: "6in" }}
                                        cover={<img style={{ margin: "0 auto", marginTop: "1.05in", width: 300, minHeight: 100 }} src={labelLogo} />}
                                    >
                                        <Row>
                                            <Col>
                                                BATCH: <span style={{ fontWeight: "bold", color: "black" }}>{row[1]}</span>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                GROSS WEIGHT:________ KG's
                                    </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Barcode value={row[0]} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                SHIP TO LOCATION:
                                        </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[2]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {row[3]}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span style={{ fontWeight: "bold", color: "black" }}>{"attn: "}{row[4]}</span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )
                })}

            </div>
        );
    }
}

class SkidLabelDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        document.getElementById("navbar").style.display = "none"
    }



    render() {
        let newSkid = localStorage.get("newSKID")
        let newRows = localStorage.get("newRows")
        return (
            <div>
                <Row type="flex" justify="space-around" align="bottom">
                    <Col >
                        <h1>Batch {newSkid.taskNumber} to {newSkid.origin}</h1>
                    </Col>
                    <Col >
                        <Button type="primary" icon="download" >
                            <ReactToPrint
                                trigger={() => <a style={{ color: "black" }} href="#">Print BarCodes or save as PDF</a>}
                                content={() => this.componentRef}
                            />
                        </Button>
                    </Col>
                </Row>

                <AllLabels ref={el => (this.componentRef = el)} />
            </div>
        )
    }

}



const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        errorMessage: state.errorMessage,
        users: state.users,
        processItems: state.processItems,
        quickBookToken: state.quickBookToken
    }
};

export default connect(mapStateToProps, actions)(SkidLabelDocument);
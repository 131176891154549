import { CREATE_SKID, GET_SKIDS, UPDATE_SKID } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_SKIDS:
            return action.payload;
        case UPDATE_SKID:
            return state.map((skid) => {
                if (skid.id === action.payload.id) {
                    // Return a new object
                    return action.payload;
                }
                return skid;
            });
        case CREATE_SKID:
            return [...state, ...action.payload]
        default:
            return state;
    }
}

// const newItem = 0;
// return [    // a new array
//     newItem,  // add the new item first
//     ...state  // then explode the old state at the end
// ];